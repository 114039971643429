
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import { WIDTH_DEVICE } from "@src/constants/constants";
// import { WIDTH_DEVICE } from "../constants/System";
import { useStores } from "../core/hooks";


const DivParentLanguage = styled.div`
  margin-right: 20px;
`
const DivLanguage = styled.button` 
  background-color: ${props => props.colors.background};
  border: none;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;

  border-radius: 4px;
  font-family: Rubik;
  font-weight: normal;
  text-align: center;
  font-size: 12px;
  color: ${props => props.colors.text};
  &:focus {
    outline:0;
  }
`

export const SwitchLanguage = observer(() => {
  const { app } = useStores()
  const { colors } = app.getTheme(app.mode)
  const language = app.language
  const [colorsEN, setColorsEN] = useState( 
    language.name === 'en' 
      ? { background: colors.buttonMainBg, text: colors.mainBottomNavigationTextLanguageActive }
      : { background: colors.mainBackground, text: colors.mainBottomNavigationTextLanguageNotActive }
  )
  const [colorsJA, setColorsJA] = useState(
    language.name === 'en' 
      ? { background: colors.mainBackground, text: colors.mainBottomNavigationTextLanguageNotActive }
      : { background: colors.buttonMainBg, text: colors.mainBottomNavigationTextLanguageActive }
  )

  useEffect(()=> {
    if (language.name === 'en') {
      setColorsEN({ background: colors.buttonMainBg, text: colors.mainBottomNavigationTextLanguageActive })
      setColorsJA({ background: colors.mainBackground, text: colors.mainBottomNavigationTextLanguageNotActive })
    } else {
      setColorsJA({ background: colors.buttonMainBg, text: colors.mainBottomNavigationTextLanguageActive })
      setColorsEN({ background: colors.mainBackground, text: colors.mainBottomNavigationTextLanguageNotActive })
    }
  }, [colors, language])

  const pressButtonLanguage = async (name) => {
    app.setLanguage({name}, true)
  }
  return (
    <DivParentLanguage>
      <DivLanguage onClick={() => pressButtonLanguage('en')} colors={colorsEN}>EN</DivLanguage>
      <DivLanguage onClick={() => pressButtonLanguage('ja')} colors={colorsJA}>JA</DivLanguage>
    </DivParentLanguage>
  );
})

export default SwitchLanguage;