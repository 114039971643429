import React, { useState, useEffect } from "react";
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import ProgressStrengthPassword from "./ProgressStrengthPassword";
import styled from 'styled-components';
// import { useStores } from "@src/core/hooks";
import { useStores } from "../core/hooks";

const DivPswdStregthMeter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 18px;
`
const LabelPswdStrengthMeterScope = styled.label`
  font-family: Rubik;
  font-size: 12px;
  color: ${props => props.color};
`

export const PasswordStrengthMeter = ({password}) => {
  const { app } = useStores()
  const { colors } = app.getTheme(app.mode)
  const colorWeak = colors.strengthMeterPasswordWeak;
  const colorNormal = colors.strengthMeterPasswordNormal;
  const colorStrong = colors.strengthMeterPasswordStrong;

  const [showPswd, setShowPswd] = useState(false)
  const [color, setColor] = useState(colorWeak)
  const [strengthPswd, setStrengthPswd] = useState(app.strings.fields.weak)

  useEffect( () => {
    const updateStrengthPswd = (scorePswd) => {
      switch (scorePswd) {
        case 0:
        case 1:
          setColor(colorWeak)
          setStrengthPswd(app.strings.fields.weak)
          break
        case 2:
        case 3:
          setColor(colorNormal)
          setStrengthPswd(app.strings.fields.normal)
          break
        case 4:
          setColor(colorStrong)
          setStrengthPswd(app.strings.fields.strong)
          break
        default:
          setColor(colorWeak)
          setStrengthPswd(app.strings.fields.weak)
          break
      }
    }

    if (typeof password === "undefined")
      setShowPswd(false)
    else {
      const scorePswd = zxcvbn(password).score
      updateStrengthPswd(scorePswd)
      setShowPswd(true)
    }
  }, [password, colorWeak, colorNormal, colorStrong, app.lang, app])

  return (
    <DivPswdStregthMeter> 
      {showPswd && <>
        <ProgressStrengthPassword color={color} score={strengthPswd}/>
        <LabelPswdStrengthMeterScope color={color}> 
          {strengthPswd}
        </LabelPswdStrengthMeterScope>
      </>
      }
    </DivPswdStregthMeter>
  );
};

PasswordStrengthMeter.propTypes = {
  password: PropTypes.string
};

PasswordStrengthMeter.defaultProps = {
  password: undefined
};

export default PasswordStrengthMeter;