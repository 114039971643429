import React, { useMemo } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import FormAuth from "@src/components/FormAuth";
import FormAuth from "../../components/FormAuth";
import ButtonColor from '../../components/ButtonColor';
import { useStores } from "../../core/hooks";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const LabelDescription = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  font-size: 16px;
  font-family: Rubik;
  font-weight: normal;
  text-align: center;
  margin-bottom: 25px;
`
const ImageContainer = styled.div`
  color: ${props => props.theme.textOrange};
  display: flex;
  align-self: center;
  height: 80px;
  width: 80px;

  img {
    height: 100%;
  }
`

export const ApproveSuccess = observer(() => {
  const { app } = useStores()
  
  const ApproveSuccessForm = (restProps) => {
    return (
      <DivContainer style={restProps.style}>
        <LabelDescription> 
          {restProps.states.strings.texts.passwordChanged}
        </LabelDescription>
        <ImageContainer>
          <img src={require('../../assets/images/hand_v.png')} alt=""/>
        </ImageContainer>
        <ButtonColor title={restProps.states.strings.buttons.ok} marginTop={'43px'} marginBottom={'56px'} onClick={() => window.location.assign('/login')}/>
      </DivContainer>
    )
  }

  const memoizedValue = useMemo(() => ApproveSuccessForm, [])

  return (
      <FormAuth 
        width="432px" 
        title={app.strings.texts.success}
        states={app}
        FormMain={memoizedValue} />
  );
});