import React, { useEffect, useState } from "react";
import styled from 'styled-components';
// import ToggleSwitch from "@app/components/ToggleSwitch";
// import { WIDTH_DEVICE } from "@app/constants/constants";
// import { useStores } from "@app/core/hooks";
import ToggleSwitch from "../components/ToggleSwitch";
import { WIDTH_FOOTER_FOR_CHANGED } from "../constants/System";
import { useStores } from "../core/hooks";
import SwitchLanguage from "../components/SwitchLanguage";

const DivFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  @media (min-width: 661px) {
    padding-top:15px;
    padding-bottom: 15px;
  }
`
const DivSwitchMobile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`
const LabelTitleFooter = styled.label`
  font-size: 13px;
  color: ${props => props.theme.mainBottomNavigationText};
  font-family: Rubik;
  text-align: center;
  margin-bottom: 0px;

  @media (min-width: 661px) {
    position: absolute;
    left: 50%;
    margin-left: -126px;
  }

  @media (max-width: 660px) {
    margin-top: 15px;
  }
`
const LabelSocialTitleFooter = styled.label`
  font-size: 13px;
  padding-top: 10px;
  margin-left: 15px;
  margin-right: auto;
  color: ${props => props.theme.mainBottomNavigationText};
  font-family: Rubik;
  padding-top: 10px;
`

export const Footer = (props) => {
  const { app } = useStores();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DivFooter>
      {widthWindow > WIDTH_FOOTER_FOR_CHANGED ? (
        <>
          <ToggleSwitch {...props} />
          <LabelSocialTitleFooter>{app.strings.texts.switchTheme}</LabelSocialTitleFooter>
          <LabelTitleFooter props={props}>© 2018-2020 Platinum. All right reserved.</LabelTitleFooter>
          <SwitchLanguage />
        </>
      ) : (
        <>
          <DivSwitchMobile>
            <ToggleSwitch {...props} />
            <LabelSocialTitleFooter>{app.strings.texts.switchTheme}</LabelSocialTitleFooter>
            <SwitchLanguage />
          </DivSwitchMobile>
          <LabelTitleFooter props={props}>© 2018-2020 Platinum. All right reserved.</LabelTitleFooter>
        </>
      )}
    </DivFooter>
  );
};