import React, { useState, useEffect, useMemo } from "react";
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// import FormAuth from "@src/components/FormAuth";
// import TextField from "@src/components/TextField";
// import ButtonColor from "@src/components/ButtonColor";
// import { useStores } from '@src/core/hooks';
// import { changeEmail } from "@src/core/server/auth";
import FormAuth from "../../components/FormAuth";
import TextField from "../../components/TextField";
import ButtonColor from "../../components/ButtonColor";
import { useStores } from '../../core/hooks';
import { changeEmail, getMe } from "../../core/server/auth";
import { WIDTH_MAIN_FORM } from "../../constants/System";

const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitleTextField = styled.div`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 8px;
`
const LabelErrorMessage = styled.label`
  font-family: Rubik;
  color: red;
  font-size: 12px;
  height: 8px;
`
const DivButtonAction = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ChangeEmail = () => {
  const { app } = useStores()
  const [storeEmail, setStoreEmail] = useState("user@email.com")

  const ChangeEmailForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')

    const schema = yup.object().shape({
        email: yup
          .string()
          .email(restProps.states.app.strings.fields.emailInvalid)
          .required(restProps.states.app.strings.fields.emailRequired),
        password: yup
          .string()
          .min(3, restProps.states.app.strings.fields.minCharacters)
          .required(restProps.states.app.strings.fields.passwordRequired),
    });

    const { register, setValue, watch, handleSubmit, errors, trigger } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
    });
    useEffect(() => {
      if (errors.email) trigger('email') 
      if (errors.password) trigger('password')
    }, [errors, trigger, restProps.states.lang])
    useEffect(() => {
      setErrorMessage('')
    }, [restProps.states.app.lang])

    useEffect(() => {
      const meAuthorized = async () => {
        const data = await getMe()
        if (data.isError && data.response.status === 401) {
          restProps.states.app.removeEmail()
          restProps.states.app.removeToken()
          window.location.assign('login')
        }
      }
      meAuthorized()
    }, [restProps.states.app])

    useEffect(() => {
      setStoreEmail(restProps.states.app.getEmail()) 
    }, [restProps.states.app])

    useEffect(() => {
      register('email')
      register('password')
    }, [register])

    const { email, password } = watch()

    const handleOnSubmit = async (formData) => {
      const data = await changeEmail({
        email: formData.email,
        password: formData.password,
        lang: restProps.states.app.lang
      });
      if (data.isError)
			  return setErrorMessage(data.errors[0].message)
		  else {
        restProps.states.app.setEmail(formData.email)
        window.location.assign('profile/')
      }
    }

    return (
      <DivForm style={restProps.style}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <LabelTitleTextField> 
            {restProps.states.app.strings.fields.newEmail}
          </LabelTitleTextField>
          <TextField 
                  placeholder={restProps.states.app.strings.fields.typeNewEmail}
                  type="text"
                  onChangeText={ (email) => {
                    setValue('email', email, { shouldValidate: true })
                    setErrorMessage('')
                  }}
                  value={email}
          />
          <LabelErrorMessage> 
            {errors?.email?.message || ''}
          </LabelErrorMessage>
          <LabelTitleTextField> 
            {restProps.states.app.strings.fields.password}
          </LabelTitleTextField>
          <TextField 
                  placeholder={restProps.states.app.strings.fields.typePassword}
                  type="password"
                  onChangeText={ (password) => {
                    setValue('password', password, { shouldValidate: true })
                    setErrorMessage('')
                  }}
                  value={password}
          />
          <LabelErrorMessage> 
            {errorMessage || errors?.password?.message || ''} 
          </LabelErrorMessage>
          <DivButtonAction>
            <ButtonColor title={restProps.states.app.strings.buttons.changeEmail}/>
          </DivButtonAction>
        </form>
      </DivForm>
    )
  }

  const memoizedValue = useMemo(() => ChangeEmailForm, [])

  return (
    <FormAuth 
      width={WIDTH_MAIN_FORM}
      title={app.strings.texts.changeEmail}
      subtitle={app.strings.formatString(app.strings.texts.currentEmail, storeEmail)}
      redirectLogin={true}
      states={{app}}
      FormMain={memoizedValue} />
  );
};