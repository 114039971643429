import React, { useState, useEffect, useMemo } from 'react';
import * as Web3 from 'web3';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import FormAuth from '../../components/FormAuth';
import TextField from '../../components/TextField';
import ButtonColor from '../../components/ButtonColor';
import { login, metamaskLogin } from '../../core/server/auth';
import { useStores } from "../../core/hooks";
import { getParamsHttpLine } from '../../core/service';
import { WIDTH_MAIN_FORM } from '../../constants/System';

const DivContinue = styled.div`
  margin: 10px 0px;
`
const DivContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgb(239, 242, 245);

  @media (max-width: 550px) {
    background-color: #fff;
  }
`
const DivSignIn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitleTextField = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
`
const DivPasswordTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`
const LinkForgotPassword = styled(Link)`
  color: #f0830e;
  font-size: 12px;
  font-family: Rubik;
  text-decoration: underline;
`
const DivSignUp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`
const DivSocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
  max-width: 420px;
  height: 100%;
`
const LinkSignUp = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  color: #f0830e;
  font-family: Rubik;
`
const LabelSignUp = styled.label`
  font-size: 12px;
  font-family: Rubik;
  color: ${props => props.theme.formAuthTextSubtitle};
`

const searchLink = (url) => {
  const linkIndex = url.indexOf('redirect_uri');
  const uri = url.slice(linkIndex,);
  const decodedParam = decodeURIComponent(decodeURI(uri));
  const href = decodedParam.slice(decodedParam.indexOf('=') + 1,);
  let text = href.slice(href.indexOf('//') + 2,);

  if (text.indexOf('/') > -1) {
    text = text.slice(0, text.indexOf('/'));
  }

  return {
    href,
    text,
  };
}

export const AuthSimple = observer((props) => {
  const { app } = useStores()
  const [image, setImage] = useState(app.getLogo())
  const displayMetaMask = !!window.ethereum;
  const location = useLocation();
  const link = searchLink(location.search);
  const metamaskFox = require(`../../assets/images/metamask-fox.svg`);

  useEffect(() => {
    setImage(app.getLogo())
  }, [app, app.mode]);

  const SignInForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [redirect, setRedirect] = useState()

    const schema = yup.object().shape({
      email: yup
        .string()
        .email(restProps.states.strings.fields.emailInvalid)
        .required(restProps.states.strings.fields.emailRequired),
      password: yup
        .string()
        .min(3, restProps.states.strings.fields.minCharacters)
        .required(restProps.states.strings.fields.passwordRequired),
    });

    const { register, setValue, watch, handleSubmit, errors, trigger } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
    });
    useEffect(() => {
      // trigger('email')
      // trigger()
      // if (formState.isSubmitted) {
        if (errors.email) trigger('email')
        if (errors.password) trigger('password')
      // }
    }, [errors, trigger, restProps.states.lang])
    useEffect(() => {
      setErrorMessage('')
    }, [restProps.states.lang])

    useEffect(() => {
      let response = getParamsHttpLine()
      if (response && response.redirect) setRedirect(response.redirect)
      if (restProps.states.token && response && response.redirect) window.location.assign(response.redirect)
    }, [restProps.states.token])

    useEffect(() => {
      restProps.states.setPlaceSignIn(true)
    }, [restProps.states])

    useEffect(() => {
      register('email')
      register('password')
    }, [register])

    const { email, password } = watch()

    const handleOnSubmit = async (formData) => {
      formData['redirect'] = redirect
      formData['lang'] = restProps.states.lang
      const data = await login(formData)
      if (data.isError) {
        if (data.response && data.response.status === 403) {
          restProps.history.push({
            pathname: '/check-inbox',
            state: { email: formData.email }
          })
        } else
        return setErrorMessage(data.errors[0].message)
      }
      else {
        restProps.states.setEmail(email)
        restProps.states.setToken(data.response.token)
        if (data.response.redirect)
          window.location.assign(data.response.redirect)
        else
          window.location.assign('profile/')
      }
    }

    const handleMetaMaskLogin = async () => {
      await window.ethereum.enable()
      const web3 = new Web3(window.ethereum)

      web3.eth.getCoinbase(async (err, publicAddress) => {
        if (err) {
          return setErrorMessage('Unexpected Metamask error')
        }
        const submitData = { publicAddress, redirect, lang: restProps.states.lang }
        const data = await metamaskLogin(submitData, web3)

        if (data.isError) {
          return setErrorMessage(data.errors[0].message)
        }
        else {
          restProps.states.setEmail(data.response.email)
          restProps.states.setToken(data.response.token)
          if (data.response.redirect)
            window.location.assign(data.response.redirect)
          else
            window.location.assign('profile/')
        }

      })
    }

    return (
      <>
        <DivSignIn style={restProps.style}>
          {link.href &&
            <DivContinue>
              To continue to <a style={{ color: '#f0830e', textDecoration: 'underline' }} rel="noopener noreferrer" href={ link.href } target="_blank">{ link.text }</a>
            </DivContinue>
          }
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <LabelTitleTextField>{restProps.states.strings.fields.email}</LabelTitleTextField>
            <TextField
              placeholder={restProps.states.strings.fields.typeEmail}
              type='text'
              onChangeText={(email) => {
                setValue('email', email, { shouldValidate: true })
                setErrorMessage('')
              }}
              value={email}
            />
            <label style={{ color: 'red', fontSize: '12px', height: '8px' }}>
              {' '}
              {errors?.email?.message ? errors?.email?.message : ''}{' '}
            </label>
            <DivPasswordTitle>
              <LabelTitleTextField>{restProps.states.strings.fields.password}</LabelTitleTextField>
              <LinkForgotPassword to='/password-recovery'>
                {restProps.states.strings.fields.forgotPassword}
              </LinkForgotPassword>
            </DivPasswordTitle>
            <TextField
              placeholder={restProps.states.strings.fields.typePassword}
              type='password'
              onChangeText={(password) => {
                setValue('password', password, { shouldValidate: true })
                setErrorMessage('')
              }}
              value={password}
            />
            <div>
              <label style={{ color: 'red', fontSize: '12px', height: '8px' }}>
                {errorMessage ? errorMessage : errors?.password?.message ? errors?.password?.message : ''}
              </label>
            </div>
            <ButtonColor title={restProps.states.strings.buttons.signIn} />
          </form>
        </DivSignIn>
        <DivSignUp>
          <LinkSignUp to={'/signup' + window.location.search}>
            {restProps.states.strings.fields.signUp}
          </LinkSignUp>
          <LabelSignUp>&nbsp;{restProps.states.strings.fields.noHaveAccount}</LabelSignUp>
        </DivSignUp>
        { displayMetaMask &&
          <DivSocialLogin>
            <ButtonColor
              colorType="none"
              icon={metamaskFox}
              onClick={handleMetaMaskLogin}
              title={restProps.states.strings.buttons.metamaskLogin}
              textStyle={{ boxShadow: '0 9px 21px 0 rgba(0, 0, 0, 0.1)', marginBottom: 35 }} />
          </DivSocialLogin>
        }
      </>
    )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedValue = useMemo(() => SignInForm, [])

  return (
    <DivContainer>
      <FormAuth
        logo={image}
        width={WIDTH_MAIN_FORM}
        title={app.strings.texts.signIn}
        history={props.history}
        states={app}
        FormMain={memoizedValue} />
    </DivContainer>
  )
})
