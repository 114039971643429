export const WIDTH_DEVICE=450;
export const WIDTH_FOOTER_FOR_CHANGED=660;

// Desktop
export const HEIGHT_TOP_BAR=72;
export const HEIGHT_BOTTOM_BAR=72;
export const PADDING_HORIZONTAL=82;
export const WIDTH_MAIN_FORM=500;

// Mobile
export const M_HEIGHT_TOP_BAR=74;
export const M_HEIGHT_BOTTOM_BAR=91;
export const M_PADDING_HORIZONTAL=27;