import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import ButtonColor from  '../components/ButtonColor';
import { WIDTH_DEVICE } from '../constants/System';
import { useStores } from '../core/hooks';

const ExtLink = styled.a`
  &:link {
    text-transform: uppercase;
    text-decoration: none;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 0px;
  }
  &:active, :focus {
    outline: none !important;
  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`

const DivHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const DivIconHeader = styled.div`
  margin-left: 20px;
  width: ${props => props.width};
  height: 26px;
`
const EmailLabel = styled.label`
  font-family: Rubik;
  font-size: 16px;

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;

  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 0px;

  color: ${props => props.theme.mainTopNavigationTextSignIn};

  @media (max-width: 450px) {
    width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; 
  }
`
const LinkButtonSignInHeader = styled(Link)`
  margin-left: auto;

  font-family: Rubik;
  font-size: 12px;
  color: ${props => props.theme.mainTopNavigationTextSignIn};
  margin-right: 15px;
  align-self: center;
  &:hover {
    text-shadow: #f0830e 1px 4px 10px;
    color: ${props => props.theme.mainTopNavigationTextSignIn};
  }
  &:link {
    text-decoration: none;
    cursor: default;
  }
`
const LinkButtonSignInHeaderChoose = styled(Link)`
  margin-left: auto;
  margin-right: 12px;
  align-self: center;
  width: 100px;
  font-size: 12px;
  &:link {
    text-decoration: none;
    cursor: default;
  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`
const LinkButtonSignUpHeader = styled(Link)`
  margin-right: 30px;
  font-family: Rubik;
  font-size: 12px;
  color: ${props => props.theme.mainTopNavigationTextSignIn};
  align-self: center;
  &:hover {
    text-shadow: #f0830e 1px 4px 10px;
    color: ${props => props.theme.mainTopNavigationTextSignIn};
  }
  &:link {
    text-decoration: none;
    cursor: default;
  }
`
const LinkButtonSignUpHeaderChoose = styled(Link)`
  font-size: 12px;
  width: 100px;
  margin-right: 30px;
  &:link {
    text-decoration: none;
    cursor: default;
  }
  &:active, :focus {
    outline: none !important;
  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`
const LinkEmail = styled(Link)`
  margin-left: auto;
  &:link {
    text-decoration: none;
    cursor: default;
  }
  &:active, :focus {
    outline: none !important;
  }
  &::-moz-focus-inner {
    border: 0 !important;
  }
`

export const Header = observer(() => {
  const { app } = useStores()
  const [image, setImage] = useState(app.getLogo())
  const [email, setEmail] = useState(app.getEmail())
  useEffect(() => {
    setImage(app.getLogo())
  }, [app, app.mode])

  useEffect(() => {
    let email = app.getEmail()
      setEmail(email)
  }, [app, app.email])

  useEffect(()=>{},[app.lang])
  return (
    <DivHeader>
      <DivIconHeader width={window.screen.width > WIDTH_DEVICE ? '131px' : '47px'}>
        <img src={image} width='100%' height='100%' alt=""/>
      </DivIconHeader>
      { email ?
        <>
          <ExtLink href='https://forum.platinum.fund/mylogin'>
            Platinum Forum
          </ExtLink>
          <LinkEmail to='/profile'>
            <EmailLabel>{email}</EmailLabel>
          </LinkEmail>
          <LinkButtonSignUpHeaderChoose to='/logout'>
            <ButtonColor title={app.strings.buttons.signOut} />
          </LinkButtonSignUpHeaderChoose>
        </>
        : app.isSignIn
          ? <>
              <ExtLink href='https://forum.platinum.fund'>
                Platinum Forum
              </ExtLink>
              <LinkButtonSignInHeaderChoose to={'/login' + window.location.search}>
                <ButtonColor title={app.strings.buttons.signIn} />
              </LinkButtonSignInHeaderChoose>
              <LinkButtonSignUpHeader to={'/signup' + window.location.search}>
                {app.strings.buttons.signUp}
              </LinkButtonSignUpHeader>
            </>
            :
              <>
                <ExtLink href='https://forum.platinum.fund'>
                  Platinum Forum
                </ExtLink>
                <LinkButtonSignInHeader to={'/login' + window.location.search}>
                {app.strings.buttons.signIn}
                </LinkButtonSignInHeader>
                <LinkButtonSignUpHeaderChoose to={'/signup' + window.location.search}>
                  <ButtonColor title={app.strings.buttons.signUp} />
                </LinkButtonSignUpHeaderChoose>
              </>
      }
    </DivHeader>
  )
});
