import React, { useState } from 'react';
import './ToggleSwitch.css';
// import { useStores } from '@src/core/hooks';
import { useStores } from '../core/hooks';
// import styled from 'styled-components'

// const Input = styled.input`
//     ${'' /* opacity: 0;
//     width: 0;
//     height: 0; */}
// `
// const LabelSwitch = styled.label`
//   position: relative;
//   display: inline-block;
//   width: 30px;
//   height: 17px;
//   margin-top: 5px;
//   input{
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;

//     &:before {
//       position: absolute;
//       content: "";
//       height: 20px;
//       width: 20px;
//       left: 0px;
//       bottom: 4px;
//       top: 0;
//       bottom: 0;
//       margin: auto 0;
//       -webkit-transition: 0.4s;
//       transition: 0.4s;
//       box-shadow: 0 0px 15px #2020203d;
//       background: white url('https://i.ibb.co/FxzBYR9/night.png');
//       background-repeat: no-repeat;
//       background-size: 75%;
//       background-position: center;
//     }
//     &:checked {
//       background-color: #2196f3;
//       &:before {
//         -webkit-transform: translateX(14px);
//         -ms-transform: translateX(14px);
//         transform: translateX(14px);
//         background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
//         background-repeat: no-repeat;
//         background-size: 75%;
//         background-position: center;
//       }
//     }
//     &:focus {
//       box-shadow: 0 0 1px #2196f3;
//     }
//   }
//   .slider.round {
//     border-radius: 17px;
//     &:before {
//       border-radius: 50%;
//     }
//   }
// `
// const SpanRound = styled.span`
//   border-radius: 17px;
//   &:before {
//     border-radius: 50%;
//   }
// `

export const ToggleSwitch = (props) => {
  const { app } = useStores()
  const [checked, setChecked] = useState(app.mode  !== 'dark')

  const toggleTheme = () => {
    let i = (app.modes.indexOf(app.mode) + 1) % app.modes.length
    app.setMode(app.modes[i])
    setChecked(!checked)
  }
    // <LabelSwitch>
    //   <input type="checkbox" onChange={toggleTheme} id="slider" checked={checked}/>
    //   <span className="slider round"/>
    // </LabelSwitch>
  return (
    <label id="switch" className="switch">
      <input type="checkbox" onChange={toggleTheme} id="slider" checked={checked}/>
      <span className="slider round"></span>
    </label>
  )
}

export default ToggleSwitch