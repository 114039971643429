import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import styled, { ThemeProvider } from "styled-components";
import { useStores } from '../core/hooks';

import '../assets/fonts/fonts.css';
import { Header } from "./Header";
import { Footer } from "./Footer";

import { SignIn } from "../screens/SignIn/SignIn";
import { ApproveCode } from "../screens/FormApproval/ApproveCode";

import { SignUp } from "../screens/SignUp/SignUp";
// import { SignIn } from "@src/screens/SignIn/SignIn";
// import { SignUp } from '@src/screens/SignUp/SignUp';

// import { PasswordRecovery } from "src/screens/ForgotPassword/PasswordRecovery";
// import { ResetPassword } from "src/screens/ForgotPassword/ResetPassword";
// import { CheckInbox } from "src/screens/ForgotPassword/CheckInbox";

// import { Profile } from "src/screens/Profile/Profile";
// import { ChangeEmail } from "src/screens/Profile/ChangeEmail";
// import { ChangePassword } from "src/screens/Profile/ChangePassword";
// import { Logout } from "src/screens/Profile/Logout";

import { PasswordRecovery } from "../screens/ForgotPassword/PasswordRecovery";
import { ApproveSuccess } from "../screens/FormApproval/ApproveSuccess";

import { CheckInbox } from "../screens/FormApproval/CheckInbox";

import { Profile } from "../screens/Profile/Profile";
import { ChangeEmail } from "../screens/Profile/ChangeEmail";
import { ChangePassword } from "../screens/Profile/ChangePassword";
import { Logout } from "../screens/Profile/Logout";
import { HEIGHT_TOP_BAR, M_HEIGHT_TOP_BAR } from "../constants/System";
import { ApproveService } from "../screens/FormApproval/ApproveService";
import { Google2FA } from '../screens/Profile/Google2FA';
import { Email2FA } from '../screens/Profile/Email2FA';

import { AuthSimple } from "../screens/AuthSimple";
import { TwoFactorSignIn } from '../screens/FormApproval/TwoFactorSignIn';

const MainApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: ${props => props.colors.mainBackground};
`

const MainTopNavigation = styled.div`
  background-color: ${props => props.colors.mainTopNavigationBackground};
  display: flex;
  align-items: center;
  border-bottom: 5px;
  border-bottom-width: 10px;

  @media (min-width: 450px) {
    height: ${HEIGHT_TOP_BAR}px;
  }
  @media (max-width: 450px) {
    height: ${M_HEIGHT_TOP_BAR}px;
  }
`

const MainBottomNavigation = styled.div`
  background-color: ${props => props.colors.mainBottomNavigationBackground};
  width: 100%;
  bottom:0;
  border-top-color: ${props => props.colors.mainBottomNavigationBorderTop};
  border-top: 0.8px solid ${props => props.colors.mainBottomNavigationBorderTop};
`
const MainForm = styled.div`
  background-color: ${props => props.colors.mainFormBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
`

export const App = observer(() => {
  const { app } = useStores()
  const { colors } = app.getTheme(app.mode)
  const location = useLocation();

  useEffect(()=>{
    window.addEventListener('languagechange', function() {
      app.updateLanguage();
    });

    return window.removeEventListener('languagechange', function() {
        app.updateLanguage();
    });
  }, [app])

  useEffect(()=>{}, [app.lang])

  return (
    <ThemeProvider theme={colors}>
      <Switch>
        <Route exact path='/auth' component={AuthSimple}/>
      </Switch>
      { location.pathname !== '/auth' &&
        <MainApp colors={colors}>
          <MainTopNavigation colors={colors}>
            <Header/>
          </MainTopNavigation>
          <MainForm colors={colors}>
            <Switch>
                <Route exact path='/' component={SignIn}/>
                <Route path='/login' component={SignIn}/>
                <Route path='/signup' component={SignUp}/>
                <Route path='/password-recovery' component={PasswordRecovery}/>
                <Route path='/check-inbox' component={CheckInbox}/>
                <Route path='/2fa-login' component={TwoFactorSignIn}/>
                <Route path='/approve-code' component={ApproveCode}/>
                <Route path='/approve-service' component={ApproveService}/>
                <Route path='/approve-success' component={ApproveSuccess}/>
                <Route path='/profile/2fa-google' component={Google2FA}/>
                <Route path='/profile/2fa-email' component={Email2FA}/>
                <Route path='/profile' component={Profile}/>
                <Route path='/change-email' component={ChangeEmail}/>
                <Route path='/change-password' component={ChangePassword}/>
                <Route path='/logout' component={Logout}/>
            </Switch>
          </MainForm>
          <MainBottomNavigation colors={colors}>
            <Footer />
          </MainBottomNavigation>
        </MainApp>
      }
    </ThemeProvider>
  );
});
