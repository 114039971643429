import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStores } from "../core/hooks";

const EditImgStyled = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`
const EditButtonStyled = styled.button`
  font-size: 14px;
  background-color: ${props => props.theme.buttonEditBg};
  border: solid 1px ${props => props.theme.buttonEditBorder};
  border-radius: 4px;
  font-family: Roboto;
  box-shadow: 1px 1px 10px 1px ${props => props.theme.buttonEditBoxSh};
  width: 100%;
  height: 100%;
  color: ${props => props.theme.buttonEditClr};

  &:focus {
    outline:0;
  }

  &:hover {
    box-shadow: 0px 4px 20px 0px ${props => props.theme.buttonEditBoxShHover};
  }
`
const EditSpanStyled = styled.span`
  display: inline-block;
  vertical-align: middle;
`

export const EditButton = ({ onClick }) => {
  const { app } = useStores()
    return (
      <EditButtonStyled>
        <EditImgStyled src={require('../assets/images/edit.png')} width='12' height='12' alt="" onClick={onClick} />
        <EditSpanStyled>{app.strings.buttons.edit}</EditSpanStyled>
      </EditButtonStyled>
    );
};

EditButton.propTypes = {
  onClick: PropTypes.func
};

EditButton.defaultProps = {
  onClick: () => {}
};

export default EditButton;