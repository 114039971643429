import React, { useMemo } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
import FormAuth from "../../components/FormAuth";
import { useStores } from "../../core/hooks";
import { TextField } from '../../components/TextField';
import ButtonColor from '../../components/ButtonColor';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { twoFactorLogin } from '../../core/server/auth';

const DivSignIn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitleTextField = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
`

export const TwoFactorSignIn = observer((props) => {
  const { handleSubmit } = useForm();
  const { app } = useStores()
  const [ values, setValues ] = useState({});
  const [ errors, setErrors ] = useState({});
  const TwoFactorBox = ({ typeName, errorMessage }) => {
    return (
      <div>
        <LabelTitleTextField>
          {app.strings.twoFactor[typeName].title}
        </LabelTitleTextField>
        <TextField
          placeholder={app.strings.twoFactor[typeName].placeholder}
          name={typeName}
          type='text'
          onChangeText = {(val) => {
            values[typeName] = val
            setValues(values)
            setErrors({})
          }}
          value = {values[typeName]}
        />
        <div>
          <label style={{ color: 'red', fontSize: '12px', height: '8px' }}>
            { errorMessage ? errorMessage : '' }
          </label>
        </div>
      </div>
    );
  }

  const TwoFactorSignInForm = (restProps) => {
    const [ globalError, setGlobalError ] = useState('');
    const handleOnSubmit = async () => {
      const formData = { ...values, uid: restProps.states.state.uid, redirect: restProps.states.state.redirect, lang: restProps.states.lang }
      const data  = await twoFactorLogin(formData);
      if (data.isError) {
        if (data.response.field) {
          errors[data.response.field] = data.response.error;
          return setErrors(errors)
        } else {
          console.log(data.response.error);
          return setGlobalError('Internal Error');
        }
      }
      else {
        restProps.states.app.setEmail(data.response.email);
        restProps.states.app.setToken(data.response.token);
        if (data.response.redirect)
          window.location.assign(data.response.redirect)
        else
          window.location.assign('profile/')
      }
    }
    return (
      <DivSignIn style={restProps.style}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
        {
          restProps.states.state.enabled2FA.map((item, index) => (
            <TwoFactorBox typeName={item} errorMessage={ errors[item] } key={ index } />
          ))}
        <ButtonColor title={app.strings.buttons.signIn} />
          <label style={{ color: 'red', fontSize: '12px', height: '8px' }}>
            { globalError ? globalError : '' }
          </label>
        </form>
      </DivSignIn>
    )
  }
  // eslint-disable-next-line 
  const memoizedValue = useMemo(() => TwoFactorSignInForm, [])

  return (
      <FormAuth
        width="432px"
        title={app.strings.texts.twoFactorAuth}
        states={{ state: props.location.state, app }}
        FormMain={memoizedValue} />
  );
});
