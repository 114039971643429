import React, { useState, useEffect } from 'react';
import RectangleMeterPassword from './RectangleMeterPassword';
import styled from 'styled-components';
// import { useStores } from '@src/core/hooks';
import { useStores } from '../core/hooks';

const DivProgressStrengthPswd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
`

export const ProgressStrengthPassword = (props) => {
  const { app } = useStores()
  const { colors } = app.getTheme(app.mode)
  const colorDefault = colors.strengthMeterPasswordDefault;
  const [color, setColor] = useState({ color1: colorDefault, color2: colorDefault, color3: colorDefault })

  useEffect(() => {
    switch (props.score) {
      case app.strings.fields.weak:
        setColor({ color1: props.color, color2: colorDefault, color3: colorDefault })
        break
      case app.strings.fields.normal:
        setColor({ color1: props.color, color2: props.color, color3: colorDefault })
        break
      case app.strings.fields.strong:
        setColor({ color1: props.color, color2: props.color, color3: props.color })
        break
      default:
        setColor({ color1: colorDefault, color2: colorDefault, color3: colorDefault })
        break
    }
  }, [props.score, props.color, colorDefault, app])

  return (
    <DivProgressStrengthPswd>
      <RectangleMeterPassword color={color.color1} />
      <RectangleMeterPassword color={color.color2} />
      <RectangleMeterPassword color={color.color3} />
    </DivProgressStrengthPswd>
  )
}

export default ProgressStrengthPassword
