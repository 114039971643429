/**
 * @file This file contains themes for styled-system.
 */

const colors = {
  main: '#ffc400',
	modes: {
    light: {
      main: '#b3c400',
      mainBackground: 'rgb(239, 242, 245)',
      mainFormBackground: 'rgb(239, 242, 245)',
      /* --color-main-form-color: white; */
    
      mainTopNavigationBackground: 'white',
      mainTopNavigationBoxShadow: 'rgba(0, 0, 0, 0.08)',
      mainTopNavigationTextSignIn: '#37474f',
    
      mainBottomNavigationBackground: 'rgb(239, 242, 245)',
      mainBottomNavigationBorderTop: 'rgb(233, 237, 240)',
      mainBottomNavigationText: 'rgba(99, 121, 132, 0.6)',
      mainBottomNavigationTextLanguageActive: '#ffffff',
      mainBottomNavigationTextLanguageNotActive: '#37474F',

      formAuthBackground: 'white',
      // formAuthBoxShadow: 0 78px 72px 0 rgba(55, 71, 79, 0.04),
      formAuthTextTitle: '#37474f',
      formAuthTextSubtitle: '#637984',
      formAuthTextLabel: '#637984',
      formAuthBackgroundContinueButton: 'white',
      formAuthBorderContinueButton: '#e3e8eb',
      formAuthBorderFooter: '#e3e8eb',
      formAuthBackgroundFooter: 'rgba(176, 190, 197, 0.1)',
      formAuthBackgroundSocialButton: '#ffffff',
      formAuthBorderSocialButton: '#e3e8eb',
      formAuthBoxShadowSocialButton: 'rgba(55, 71, 79, 0.1)',
      // formAuthBoxShadowNewSocialButton: 0 9px 21px 0 var(--color-form-auth-box-shadow-social-button),
    
      textfieldPlaceholder: '#8d9ea7',
      textfieldText: '#37474f',
      textfieldBackground: '#f9fbfc',
      textfieldBorder: '#e3e8eb',
      textfieldBoxShadowHover: 'rgba(0, 0, 0, 0.05)',
      textOrange: '#f0830e',
      textVerified: '#4BC01E',
      
      strengthMeterPasswordDefault: '#eef2f4',
      strengthMeterPasswordWeak: '#ff6f6f',
      strengthMeterPasswordNormal: '#ffc400',
      strengthMeterPasswordStrong: '#35cb90',

        /* --- Buttons --- */
      buttonLogoutBg: '#fff',
      buttonLogoutBorder: '#E3E8EB',
      buttonLogoutBoxSh: '#e3e8eb2e',
      buttonLogoutBgHover: '#fff',
      buttonLogoutBoxShHover: 'rgba(0, 0, 0, 0.1)',
      buttonLogoutClr: '#092959',

      buttonMainBg: '#f0830e',
      buttonMainBorder: '#ee6f00',
      buttonMainBoxSh: 'rgba(240, 131, 14, 0.18)',
      buttonMainBgHover: 'rgb(240, 131, 14, 0.5)',
      buttonMainBoxShHover: 'rgba(0, 0, 0, 0.1)',
      buttonMainClr: '#fff',

      buttonEditBg: '#ffffff',
      buttonEditBorder: '#fff',
      buttonEditBoxSh: '#E3E8EB',
      buttonEditBoxShHover: 'rgba(0, 0, 0, 0.1)',
      buttonEditClr: '#f0830e',
    },
		dark: {
      mainBackground: '#11172b',
      mainFormBackground: '#11172b',
      /* --color-main-form-color: white; */
    
      mainTopNavigationBackground: 'rgba(255, 255, 255, 0.04)',
      mainTopNavigationBoxShadow: 'rgba(55, 71, 79, 0.04)',
      mainTopNavigationTextSignIn: 'rgba(255, 255, 255, 0.8)',
      mainBottomNavigationTextLanguageActive: '#ffffff',
      mainBottomNavigationTextLanguageNotActive: '#ffffff',
    
      mainBottomNavigationBackground: '#11172b;',
      mainBottomNavigationBorderTop: 'rgba(227, 232, 235, 0.4)',
      mainBottomNavigationText: 'rgba(99, 121, 132)', 
    
      formAuthBackground: 'rgba(255, 255, 255, 0.1)',
      // formAuthBoxShadow: 0 78px 72px 0 rgba(227, 232, 235, 0.4),
      formAuthTextTitle: 'rgb(255, 255, 255)',
      formAuthTextSubtitle: 'rgba(255, 255, 255, 0.6)',
      formAuthTextLabel: 'rgba(255, 255, 255, 0.8)',
      formAuthBackgroundContinueButton: '#272d3f',
      formAuthBorderContinueButton: '#707581',
      formAuthBorderFooter: 'rgba(255, 255, 255, 0.1)',
      formAuthBackgroundFooter: 'rgba(255, 255, 255, 0.1)',
      formAuthBackgroundSocialButton: 'rgba(17, 23, 43, 0.5)',
      formAuthBorderSocialButton: 'rgba(169, 174, 181, 0.5)',
      formAuthBoxShadowSocialButton: 'rgba(169, 174, 181, 0.5)',
      // formAuthBoxShadowNewSocialButton: 0 9px 21px 0 var(--color-form-auth-box-shadow-social-button),
    
      textfieldPlaceholder: '#8d9ea7',
      textfieldText: 'rgba(255, 255,255, 0.9)',
      textfieldBackground: 'rgba(0, 0, 0, 0.1)',
      textfieldBorder: 'rgba(227, 232, 235, 0.7)',
      textfieldBoxShadowHover: 'rgba(255, 255, 255, 0.1)',
      textOrange: '#f0830e',
      textVerified: '#95F66F',
      
      strengthMeterPasswordDefault: '#ffffff1a',
      strengthMeterPasswordWeak: '#ff6f6f',
      strengthMeterPasswordNormal: '#ffc400',
      strengthMeterPasswordStrong: '#35cb90',

      /* --- Buttons --- */
      buttonLogoutBg: 'rgba(255, 255, 255, 0.04)',
      buttonLogoutBorder: '#E3E8EB',
      buttonLogoutBoxSh: 'rgba(55, 71, 79, 0.1)',
      buttonLogoutBgHover: 'rgba(255, 255, 255, 0.04)',
      buttonLogoutBoxShHover: 'rgba(55, 71, 79, 0.5)',
      buttonLogoutClr: '#fff',
        
      buttonMainBg: '#f0830e',
      buttonMainBorder: '#ee6f00',
      buttonMainBoxSh: 'rgba(240, 131, 14, 0.18)',
      buttonMainBgHover: 'rgb(240, 131, 14, 0.5)',
      buttonMainBoxShHover: 'rgba(0, 0, 0, 0.1)',
      buttonMainClr: '#fff',
        
      buttonEditBg: 'rgba(17, 23, 43, 0.5)',
      buttonEditBorder: 'rgba(17, 23, 43, 0.01)',
      buttonEditBoxSh: 'rgba(17, 23, 43, 0.1)',
      buttonEditBoxShHover: 'rgba(17, 23, 43, 0.5)',
      buttonEditClr: '#f0830e',

      // --color-button-logout-bg: rgba(255, 255, 255, 0.04);
      // --color-button-logout-border: #E3E8EB;
      // --color-button-logout-boxSh: rgba(55, 71, 79, 0.1);
      // --color-button-logout-bgHover: rgba(255, 255, 255, 0.04);
      // --color-button-logout-boxShHover: rgba(55, 71, 79, 0.5);
      // --color-button-logout-clr: #fff;
      
      // --color-button-main-bg: #f0830e;
      // --color-button-main-border: #ee6f00;
      // --color-button-main-boxSh: rgba(240, 131, 14, 0.18);
      // --color-button-main-bgHover: rgb(240, 131, 14, 0.5);
      // --color-button-main-boxShHover: rgba(0, 0, 0, 0.1);
      // --color-button-main-clr: #fff;
    
      // --color-button-edit-border: rgba(17, 23, 43, 0.01);
      // --color-button-edit-boxSh: rgba(17, 23, 43, 0.1);
      // --color-button-edit-boxShHover: rgba(17, 23, 43, 0.5);
      // --color-button-edit-clr: #f0830e;
		},
	},
}

export default { colors }