import {
  requestToApi,
  addMetaFieldsWithApiResponse,
} from './common'
import { getToken } from '../storage';

//* Sign IN
export const login = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'auth/login',
      method: 'post',
      body: fields,
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Visible-Redirect': '*',
        'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Allow-Origin': 'https://auth-service.cryptozaurus.com/',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const twoFactorLogin = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'auth/2fa-login',
      method: 'post',
      body: fields,
      redirect: 'follow',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Visible-Redirect': '*',
        'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Allow-Origin': 'https://auth-service.cryptozaurus.com/',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const metamaskLogin = async({ lang, publicAddress, redirect, ...fields }, web3) => {
  const res = addMetaFieldsWithApiResponse(await requestToApi({
    endpoint: `auth/mm-nonce/${publicAddress}`,
    method: 'get',
    redirect: 'follow',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Visible-Redirect': '*',
      'Access-Control-Allow-Credentials': true,
      'Accept-Language': lang || 'en'
    }
  }))
  if (res.isError) {
    return res;
  }
  try {
    const sign = await web3.eth.personal.sign(`By signing this I will login to Platinum Auth Service. My one time nonce is: ${res.response.nonce}`, publicAddress)
    return addMetaFieldsWithApiResponse(
      await requestToApi({
        endpoint: 'auth/mm-login',
        method: 'post',
        body: { publicAddress, sign, redirect },
        redirect: 'follow',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Visible-Redirect': '*',
          'Access-Control-Allow-Credentials': true,
          'Accept-Language': lang || 'en'
        }
      })
    )
  } catch (error){
    return addMetaFieldsWithApiResponse({error: {message: 'Signing error'}})
  }
}

//* Sign UP
export const signup = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'auth/signup',
      method: 'post',
      body: fields,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Visible-Redirect': '*',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const decision = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'auth/decision',
      method: 'post',
      body: fields,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Visible-Redirect': '*',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

//* Approve
export const checkCode = async ({ _id, code, lang }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `codes/check?_id=${_id}&approveCode=${code}`,
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const approve = async ({ _id, code, lang }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `codes/approve`,
      method: 'post',
      body: {
        _id: _id,
        approveCode: code
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

//* Recovery password
export const resetPassword = async ({ email, lang }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `auth/resetPassword`,
      method: 'post',
      body: {
        email: email
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const recoveryPassword = async ({ _id, code, newPassword, lang }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `codes/approve`,
      method: 'post',
      body: {
        _id: _id,
        approveCode: code,
        newPassword: newPassword
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

//* Profile
export const logout = async ({ redirect, lang }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: redirect ? `auth/logout?redirect=${redirect}` : `auth/logout`,
      method: 'get',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Visible-Redirect': '*',
        'Access-Control-Allow-Credentials': true,
        // 'Access-Control-Allow-Origin': 'https://auth-service.cryptozaurus.com/',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const getMe = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/me`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}
export const getGoogle2FA = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/2fa/google`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}
export const disableGoogle2FA = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/2fa/google/disable`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}
export const enableGoogle2FA = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'users/2fa/google/enable',
      method: 'post',
      body: fields,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const getEmail2FA = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/2fa/email`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}
export const disableEmail2FA = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/2fa/email/disable`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}
export const enableEmail2FA = async () => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: `users/2fa/email/enable`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
  )
}

export const changePassword = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'users/change/password',
      method: 'post',
      body: fields,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}

export const changeEmail = async ({ lang, ...fields }) => {
  return addMetaFieldsWithApiResponse(
    await requestToApi({
      endpoint: 'users/change/email',
      method: 'post',
      body: fields,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': lang || 'en'
      }
    })
  )
}
