import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
// import FormAuth from "@src/components/FormAuth";
// import EditButton from "@src/components/EditButton";
// import { useStores } from '@src/core/hooks';
import FormAuth from "../../components/FormAuth";
import EditButton from "../../components/EditButton";
import { useStores } from '../../core/hooks';
import { getMe } from "../../core/server/auth";

const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitle = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`
const LabelEmailValue = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  height: 14px;
  
  @media (max-width: 450px) {
    align-self: start;
    margin-bottom: 24px;
  }
`
const Label2FAValue = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  height: 14px;
  
  @media (max-width: 450px) {
    align-self: start;
    margin-bottom: 24px;
  }
`
const LabelPasswordValue = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  letter-spacing: 4px;
  color: ${props => props.theme.formAuthTextLabel};

  @media (max-width: 450px) {
    align-self: start;
    margin-bottom: 24px;
  }
  
  @media (min-width: 450px) {
    height: 4px;
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (min-width: 450px) {
    height: 40px;
  }
`
const VerifiedBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const LabelVerified = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-self: center;
  color: ${props => props.theme.textVerified};
  margin-bottom: 0;
  margin-left: 8px;
`
const LinkRedirect = styled(Link)`
  width: 100%;
  height: 40px;

  @media (min-width: 450px) {
    width: 72px;
  };
`
const DivSpace = styled.div`
  flex-direction: column;
  height: 24px;
`

export const Profile = () => {
  const { app } = useStores()
  const ProfileForm = (restProps) => {
    const [googleEnabled, setGoogleEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false);
    const { colors } = restProps.states.app.getTheme(restProps.states.app.mode)
    const [email, setEmail] = useState('');
    const [icon, setIcon] = useState(require('../../assets/images/icon_tick_light.png'))
    useEffect(() => {
      setEmail(restProps.states.app.getEmail())
    }, [restProps.states.app])

    useEffect(() => {
      const meAuthorized = async () => {
        const data = await getMe()
        const { enabledTwoFactor } = data.response.user;
        if (enabledTwoFactor) {
          if (enabledTwoFactor.includes('google')) {
            setGoogleEnabled(true);
          }
          if (enabledTwoFactor.includes('email')) {
            setEmailEnabled(true);
          }
        }
        if (data.isError && data.response.status === 401) {
          restProps.states.app.removeEmail()
          restProps.states.app.removeToken()
          window.location.assign('login')
        }
      }

      meAuthorized()
    }, [restProps.states.app])

    useEffect(() => {
      if (restProps.states.app.mode === 'light')
        setIcon(require('../../assets/images/icon_tick_light.png'))
      else
        setIcon(require('../../assets/images/icon_tick_dark.png'))
    }, [restProps.states.app.mode])

    const onChangeEmail = () => {
      // TODO: @melekhin send new password
    }

    const onChangePassword = () => {
      // TODO: @melekhin send new password
    }

    return (
      <DivForm style={restProps.style} >
          <LabelTitle style={{color: colors.formAuthTextLabel}} >
            {restProps.states.app.strings.fields.email}
          </LabelTitle>
          <Box>
            <LabelEmailValue style={{color: colors.formAuthTextLabel}}>
              {email}
            </LabelEmailValue>
            <LinkRedirect to='/change-email'>
              <EditButton onClick={onChangeEmail}/>
            </LinkRedirect>
          </Box>
          <VerifiedBox>
            <img src={icon} width='12' height='12' alt="" />
            <LabelVerified>{restProps.states.app.strings.fields.verified}</LabelVerified>
          </VerifiedBox>
          <DivSpace/>
          <LabelTitle style={{color: colors.formAuthTextLabel}}>
          {restProps.states.app.strings.fields.password}
          </LabelTitle>
          <Box>
            <LabelPasswordValue style={{color: colors.formAuthTextLabel}}>
              ********
            </LabelPasswordValue>
            <LinkRedirect to='/change-password'>
              <EditButton onClick={onChangePassword}/>
            </LinkRedirect>
          </Box>
          <DivSpace/>

        <LabelTitle style={{color: colors.formAuthTextLabel}}>
          {restProps.states.app.strings.fields.google2fa}
        </LabelTitle>
        <Box>
          <Label2FAValue style={{color: googleEnabled?'green':'red'}}>
            {googleEnabled ? restProps.states.app.strings.fields.enabled:restProps.states.app.strings.fields.disabled}
          </Label2FAValue>
          <LinkRedirect to='/profile/2fa-google'>
            <EditButton />
          </LinkRedirect>
        </Box>
        <DivSpace/>
        <LabelTitle style={{color: colors.formAuthTextLabel}}>
          {restProps.states.app.strings.fields.email2fa}
        </LabelTitle>
        <Box>
          <Label2FAValue style={{color: emailEnabled?'green':'red'}}>
            {emailEnabled ? restProps.states.app.strings.fields.enabled:restProps.states.app.strings.fields.disabled}
          </Label2FAValue>
          <LinkRedirect to='/profile/2fa-email'>
            <EditButton />
          </LinkRedirect>
        </Box>
        <DivSpace/>

      </DivForm>
    )
  }

  return (
    <FormAuth
      width="430px"
      title={app.strings.texts.myProfile}
      redirectLogin={true}
      states={{app}}
      FormMain={ProfileForm}/>
  );
};
