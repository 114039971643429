import React, { useState, useEffect, useMemo } from "react";
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// import FormAuth from "@src/components/FormAuth";
// import TextField from "@src/components/TextField";
// import ButtonColor from "@src/components/ButtonColor";
// import { PasswordStrengthMeter } from '@src/components/PasswordStrengthMeter';
// import { changePassword } from "@src/core/server/auth";
import FormAuth from "../../components/FormAuth";
import TextField from "../../components/TextField";
import ButtonColor from "../../components/ButtonColor";
import { useStores } from '../../core/hooks';
import { PasswordStrengthMeter } from '../../components/PasswordStrengthMeter';
import { changePassword, getMe } from "../../core/server/auth";
import { WIDTH_MAIN_FORM } from "../../constants/System";

const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitleTextField = styled.div`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 8px;
`
const LabelErrorMessage = styled.label`
  font-family: Rubik;
  color: red;
  font-size: 12px;
  height: 8px;
`
const DivButtonAction = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ChangePassword = () => {
  const { app } = useStores()

  const ChangePasswordForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')

    const schema = yup.object().shape({
        currentPassword: yup
          .string()
          .min(3, restProps.states.app.strings.fields.minCharacters)
          .required(restProps.states.app.strings.fields.passwordRequired),
        password: yup
          .string()
          .min(3, restProps.states.app.strings.fields.minCharacters)
          .required(restProps.states.app.strings.fields.passwordRequired),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], restProps.states.app.strings.fields.passwordsMatch)
          .required(restProps.states.app.strings.fields.passwordRequired),
    });

    const { register, setValue, watch, handleSubmit, errors, trigger } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
    });
    useEffect(() => {
      if (errors.currentPassword) trigger('currentPassword') 
      if (errors.password) trigger('password')
      if (errors.confirmPassword) trigger('confirmPassword')
    }, [errors, trigger, restProps.states.lang])
    useEffect(() => {
      setErrorMessage('')
    }, [restProps.states.app.lang])

    useEffect(() => {
      const meAuthorized = async () => {
        const data = await getMe()
        if (data.isError && data.response.status === 401) {
          restProps.states.app.removeEmail()
          restProps.states.app.removeToken()
          window.location.assign('login')
        }
      }

      meAuthorized()
    }, [restProps.states.app])

    useEffect(() => {
      register('currentPassword')
      register('password')
      register('confirmPassword')
    }, [register])

    const { currentPassword, password, confirmPassword } = watch()

    const handleOnSubmit = async (formData) => {
      const data = await changePassword({
        oldPassword: formData.currentPassword,
        newPassword: formData.password,
        lang: restProps.states.app.lang
      });
      if (data.isError)
			  return setErrorMessage(data.errors[0].message)
		  else {
        window.location.assign('profile/')
      }
    }

    return (
      <DivForm style={restProps.style}>

      <form onSubmit={handleSubmit(handleOnSubmit)}>
          <LabelTitleTextField> 
            {restProps.states.app.strings.fields.currentPassword}
          </LabelTitleTextField>
          <TextField 
                  placeholder={restProps.states.app.strings.fields.typeCurrentPassword}
                  type="password"
                  onChangeText={ (currentPassword) => {
                    setValue('currentPassword', currentPassword, { shouldValidate: true })
                    setErrorMessage('')
                  }}
                  value={currentPassword}
          />
          <LabelErrorMessage> 
            { errors?.currentPassword?.message || ''}
          </LabelErrorMessage>
          <LabelTitleTextField> 
            {restProps.states.app.strings.fields.newPassword}
          </LabelTitleTextField>
          <TextField 
                  placeholder={restProps.states.app.strings.fields.typeNewPassword}
                  type="password"
                  onChangeText={ (password) => {
                    setValue('password', password, { shouldValidate: true })
                    setErrorMessage('')
                    if (confirmPassword) 
                      trigger('confirmPassword')
                  }}
                  value={password}
          />
          <PasswordStrengthMeter password={password} />
          <LabelErrorMessage> 
            { errors?.password?.message || ''}
          </LabelErrorMessage>
          <LabelTitleTextField> 
            {restProps.states.app.strings.fields.confirmNewPassword}
          </LabelTitleTextField>
          <TextField 
                  placeholder={restProps.states.app.strings.fields.typeNewPassword}
                  type="password"
                  onChangeText={ (confirmPassword) => {
                    setValue('confirmPassword', confirmPassword, { shouldValidate: true })
                    setErrorMessage('')
                  }}
                  value={confirmPassword}
          />
          <PasswordStrengthMeter password={password} />
          <LabelErrorMessage> 
            { errorMessage || errors?.confirmPassword?.message || ''} 
          </LabelErrorMessage>
          <DivButtonAction>
            <ButtonColor title={restProps.states.app.strings.buttons.changePassword}/>
          </DivButtonAction>
        </form>
      </DivForm>
    )
  }

  const memoizedValue = useMemo(() => ChangePasswordForm, [])

  return (
    <FormAuth 
      width={WIDTH_MAIN_FORM} 
      title={app.strings.texts.changePassword}
      redirectLogin={true}
      states={{app}}
      FormMain={memoizedValue} />
  );
};