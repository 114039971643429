import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import styled from 'styled-components';
// import { useStores } from "@src/core/hooks";
import { useStores } from "../core/hooks";

const DivTextField = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-color: ${props => props.theme.textfieldBackground};
  border: solid 0.8px ${props => props.theme.textfieldBorder};

  &:hover {
    box-shadow: 0px 4px 20px 0px ${props => props.theme.textfieldBoxShadowHover};
  }
  input {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.textfieldBackground};
    padding: 0px 16px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-family: Rubik;
    color: ${props => props.theme.textfieldText};
    &:focus {
      outline-offset: 0px !important;
      outline: none !important;
      box-shadow: 2px 4px 20px 2px ${props => props.theme.textfieldBoxShadowHover};
    }
    &::-webkit-input-placeholder {
      color: ${props => props.theme.textfieldPlaceholder};
    }
    &::-moz-placeholder {
      color: ${props => props.theme.textfieldPlaceholder};
    }
    &::-ms-input-placeholder {
      color: ${props => props.theme.textfieldPlaceholder};
    }
  }
`
const DivIcon = styled.div`
  display: flex;
  width: 30px;
  background-color: ${props => props.theme.textfieldBackground};
  img {
    display: flex;
    align-self: center;
  }
`

export const TextField = observer((props) => {
    const { app } = useStores()
    const [inputType, setInputType] = useState(props.type)
    const [icon, setIcon] = useState({ seen: require('../assets/images/icon-eye-seen-light.png'), hide: require('../assets/images/icon-eye-hide-light.png') })
  
    const { style, type, value, onChangeText, ...restProps } = props
    const isPswd = type === "password"
    var iconEye = inputType !== "text" ? icon.hide : icon.seen
  
    useEffect(() => {
      if (app.mode === 'light')
        setIcon({seen: require('../assets/images/icon-eye-seen-light.png'), hide: require('../assets/images/icon-eye-hide-light.png')})
      else 
        setIcon({seen: require('../assets/images/icon-eye-seen-dark.png'), hide: require('../assets/images/icon-eye-hide-dark.png')})
    }, [app.mode])

    const changeValue = (event) => {
      let text = event.target.value
      onChangeText(text)
    }
    
    const changeType = () => {
      if (isPswd) {
        setInputType( inputType !== "text" ? "text" : "password")
      }
    }

    return (
      <DivTextField>
        <input 
          type={inputType}
          value={value || ""}
          {...restProps}
          style={style}
          onChange={changeValue}
        />
        {isPswd &&
        <DivIcon>
          <img src={iconEye} width='22' height='16' onClick={() => {changeType()}} alt='' />
        </DivIcon>}
      </DivTextField>
    );
})


export default TextField;