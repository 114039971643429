import React from 'react';
// import { AppStore } from '@src/core/stores/app';
import { AppStore } from '../../core/stores/app';
// import { AppStore } from '@src/core/stores'

export const app = new AppStore()
/**
 * Creates react context that stores MobX stores, it simplifies connection of
 * mobx store to react component.
 */
export const storesContext = React.createContext({ app })