import React, { useEffect, useMemo, useState } from "react";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import FormAuth from "@src/components/FormAuth";
// import TextField from "@src/components/TextField";
// import ButtonColor from '@src/components/ButtonColor';
import FormAuth from "../../components/FormAuth";
import TextField from "../../components/TextField";
import ButtonColor from '../../components/ButtonColor';
import { resetPassword } from "../../core/server/auth";
import { useStores } from "../../core/hooks";
import { WIDTH_MAIN_FORM } from "../../constants/System";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const LabelEmailTitle = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
`

export const PasswordRecovery = observer((props) => {
  const { app } = useStores()

  const PasswordRecoveryForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')

    const schema = yup.object().shape({
      email: yup
        .string()
        .email(restProps.states.app.strings.fields.emailInvalid)
        .required(restProps.states.app.strings.fields.emailRequired),
    });

    const { register, setValue, watch, handleSubmit, errors, trigger } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
    })

    useEffect(() => {
      if (errors.email) trigger('email') 
    }, [errors, trigger, restProps.states.app.lang])
    useEffect(() => {
      setErrorMessage('')
    }, [restProps.states.app.lang])

    useEffect(() => {
      register('email')
    }, [register])

    const { email } = watch()

    const handleOnSubmit = async (formData) => {
      const data = await resetPassword({ email: formData.email, lang: restProps.states.app.lang })
      if (data.isError) return setErrorMessage(data.errors[0].message)
      else {
        restProps.history.push({
          pathname: '/check-inbox',
          state: { email: formData.email }
        })
      }
    }

    return (
      <DivContainer style={restProps.style}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <LabelEmailTitle> 
            {restProps.states.app.strings.fields.email}
          </LabelEmailTitle>
          <TextField 
            placeholder={restProps.states.app.strings.fields.typeEmail}
            type="text"
            onChangeText={email => {
              setValue('email', email, { shouldValidate: true })
              setErrorMessage('')
            }}
            value={email}
          />
          <div>
            <label style={{ color: 'red', fontSize: '12px', height: '8px' }}>
              {errorMessage || errors?.email?.message || ''}
            </label>
          </div>
          <ButtonColor title={restProps.states.app.strings.buttons.send} marginBottom='56px' marginTop='8px' />
        </form>
      </DivContainer>
    )
  }

  const memoizedValue = useMemo(() => PasswordRecoveryForm, [])

  return (
      <FormAuth
        width={WIDTH_MAIN_FORM}
        history={props.history}
        title={app.strings.texts.passwordRecovery}
        subtitle={app.strings.formatString(app.strings.texts.sendEmail, <br/>)}
        states={{app}}
        FormMain={memoizedValue} />
  );
});