export const getTheme =  () => {
    return localStorage.getItem('theme') || 'light'
}

export const setTheme =  (theme) => {
    localStorage.setItem('theme', theme)
}

export const getEmailStore =  () => {
    return localStorage.getItem('email') || undefined
}

export const getTwoFactorStore = () => {
    return localStorage.getItem('twoFactor') || false
}

export const removeTwoFactorStore =  () => {
    localStorage.removeItem('twoFactor')
}

export const setEmailStore =  (email) => {
    localStorage.setItem('email', email)
}

export const removeEmailStore =  () => {
    localStorage.removeItem('email')
}

export const setToken =  (token) => {
    localStorage.setItem('token', token)
}

export const getToken =  () => {
    return localStorage.getItem('token') || undefined
}

export const removeToken =  () => {
    localStorage.removeItem('token')
}

export const setPlaceSignIn =  (isSignIn) => {
    localStorage.setItem('isSignIn', isSignIn)
}

export const getPlaceSignIn =  () => {
    return localStorage.getItem('isSignIn') || true
}

export const setLanguage =  (lang) => {
    localStorage.setItem('language', lang)
}

export const getLanguage =  () => {
    return localStorage.getItem('language') || undefined
}

export const removeLanguage =  () => {
    localStorage.removeItem('language')
}
