import React, { useState, useEffect } from "react";
import styled from 'styled-components';
// import FormAuth from "@src/components/FormAuth";
// import ButtonColor from "@src/components/ButtonColor";
// import { useStores } from '@src/core/hooks';
// import { logout } from "@src/core/server/auth";
import FormAuth from "../../components/FormAuth";
import ButtonColor from "../../components/ButtonColor";
import { useStores } from '../../core/hooks';
import { logout, getMe } from "../../core/server/auth";
import { WIDTH_MAIN_FORM } from "../../constants/System";


const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (min-width: 450px) {
    height: 40px;
  }
`
const DivSpace = styled.div`
  flex-direction: column;
  height: 24px;
`

const MarginSpace = styled.div`
  @media (max-width: 450px) {
    margin-top: 24px;
  }
  @media (min-width: 450px) {
    margin-left: 32px;
  }
`

export const Logout = () => {
  const { app } = useStores()
  const email = app.getEmail()
  const [redirect, setRedirect] = useState()

  useEffect(() => {
    const meAuthorized = async () => {
      const data = await getMe()
      if (data.isError && data.response.status === 401) {
        app.removeEmail()
        app.removeToken()
        window.location.assign('login')
      }
    }

    meAuthorized()
  }, [app])

  useEffect(() => {
    const getParamsHttpLine = () => {
      let params = window.location.search
        .replace('?', '')
        .split('&')
        .reduce(function (p, e) {
          var a = e.split('=')
          p[decodeURIComponent(a[0])] = decodeURIComponent(a[1])
          return p
        }, {})
      if (!params['redirect']&&params['redirect_uri']) {
        params['redirect'] = params['redirect_uri']
      }
      if (params && params['redirect']) {
        if (email)
          setRedirect(params['redirect'])
        else
          window.location.assign(params['redirect'])
      }
      else if (typeof email === 'undefined')
        window.location.assign('login')
    }

    getParamsHttpLine()
  }, [email])

  const LogoutForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')

    const yesButtonPress = async () => {
      let formData = { redirect: redirect, lang: restProps.states.app.lang }
      const data = await logout(formData)
      if (data.isError) return setErrorMessage(data.errors[0].message)
      else {
        restProps.states.app.removeEmail()
        restProps.states.app.removeToken()
        if (redirect && data.response.redirect) {
          window.location.assign(data.response.redirect)
        } else {
          window.location.assign('login')
        }
      }
    }

    const noButtonPress = () => {
      if (redirect) {
          window.location.assign(redirect)
      } else {
          window.location.assign('profile/')
      }
    }

    return (
      <DivForm style={restProps.style} >
        <Box>
          <ButtonColor colorType="none" title={restProps.states.app.strings.buttons.no} onClick={noButtonPress}/>
          <MarginSpace />
          <ButtonColor colorType="none" title={restProps.states.app.strings.buttons.yes} onClick={yesButtonPress}/>
        </Box>
        <label style={{ color: 'red', fontSize: '12px', height: '8px', marginTop: '8px' }}>
          {errorMessage ? errorMessage : ''}
        </label>
        <DivSpace/>
      </DivForm>
    )
  }

  return (
    <>
      { email
        ?  <FormAuth
              width={WIDTH_MAIN_FORM}
              title={app.strings.texts.signOut}
              subtitle={app.strings.formatString(app.strings.texts.signOutAccount, <br />)}
              states={{app}}
              // redirectLogin={true}
              FormMain={LogoutForm} />
        :  <></>}
    </>
  );
};
