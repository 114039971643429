import React from "react";
import styled from 'styled-components';
// import { WIDTH_DEVICE } from "@src/constants/constants";
import { WIDTH_DEVICE } from "../constants/System";

const DivRectangleMeterPassword = styled.div` 
  width: 64px;
  height: 8px;
  border-radius: 3px;
  margin-right: 8px;
`
export const RectangleMeterPassword = (props) => {
  return (
    <DivRectangleMeterPassword style={{ backgroundColor: props.color, width: window.screen.width > WIDTH_DEVICE ? '64px' : '51px' }} />
  );
}

export default RectangleMeterPassword;