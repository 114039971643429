import { isEmpty, flatMapDeep, pickBy } from "lodash";

const SERVER_URL = "";

export const addMetaFieldsWithApiResponse = ({ error, response }) => {
  // console.log(response)
  const errors = flatMapDeep(pickBy(response, "errors"), (v, entity) => {
    return flatMapDeep(v, (v) => {
      return flatMapDeep(v, (messages, field) => {
        return flatMapDeep(messages, (message) => {
          return {
            entity,
            field,
            message,
          };
        });
      });
    });
  });

  if (response && response.error)
    errors.push({
      message: response.error,
      entity: "api",
    });

  if (error)
    errors.push({
      message: error.message,
      entity: "internal",
    });

  const isError = !isEmpty(errors);

  return {
    isError,
    errors,
    response,
  };
};

export const requestToApi = async ({
  endpoint,
  method,
  body,
  redirect,
  multipart = false,
  headers,
}) => {
  try {
    const url = `${SERVER_URL}/api/${endpoint}`;
    const options = {
      credentials: "same-origin",
      method,
      headers,
      mode: "same-origin",
      //   cache: 'no-cache',
      redirect,
      body: multipart ? body : JSON.stringify(body),
    };

    return {
      response: await (await fetch(url, options)).json(),
    };
  } catch (error) {
    return {
      error,
    };
  }
};