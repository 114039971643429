import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import styled from 'styled-components';
// import { observer } from "mobx-react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
// import FormAuth from "@src/components/FormAuth";
// import TextField from "@src/components/TextField";
// import ButtonColor from '@src/components/ButtonColor';
// import PasswordStrengthMeter from "@src/components/PasswordStrengthMeter";
// import FormAuth from "../../components/FormAuth";
import TextField from "../../../components/TextField";
import ButtonColor from '../../../components/ButtonColor';
import PasswordStrengthMeter from "../../../components/PasswordStrengthMeter";
import { recoveryPassword } from "../../../core/server/auth";
import { useStores } from "../../../core/hooks";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const LabelTitleField = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  margin-top: ${props => props.marginTop};
`
const LabelErrorMessage = styled.label`
  font-family: Rubik;
  color: red;
  font-size: 12px;
  height: 8px;
`

export const ResetPasswordSend = (restProps) => {
  const { app } = useStores()
  const [errorMessage, setErrorMessage] = useState('')

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(3, app.strings.fields.minCharacters)
      .required(app.strings.fields.passwordRequired),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], app.strings.fields.passwordsMatch)
      .required(app.strings.fields.passwordRequired),
  });
  const { register, setValue, handleSubmit, trigger, errors, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (errors.password) trigger('password')
    if (errors.confirmPassword) trigger('confirmPassword')
  }, [errors, trigger, restProps.states.app.lang])
  useEffect(() => {
    setErrorMessage('')
  }, [restProps.states.app.lang])

  useEffect( () => {
    register('password')
    register('confirmPassword')
  },[register])

  const { password, confirmPassword } = watch()

  const pressResetPassword = async (formData) => {
    const data = await recoveryPassword({
      _id: restProps.states.id,
      code: restProps.states.code,
      newPassword: formData.password,
      lang: app.lang
    });
    if (data.isError) return setErrorMessage(data.errors[0].message)
    else {
      window.location.assign('/approve-success')
    }
   }

  return (
    <DivContainer style={restProps.style}>
      <form onSubmit={handleSubmit(pressResetPassword)}>
        <LabelTitleField>{app.strings.fields.newPassword}</LabelTitleField>
        <TextField
          placeholder={app.strings.fields.typeNewPassword}
          type="password"
          onChangeText={async (password) => {
            setValue("password", password, { shouldValidate: true });
            setErrorMessage("");
            if (confirmPassword) trigger("confirmPassword")
          }}
          value={password}
        />
        <PasswordStrengthMeter password={password} />
        <div>
          <LabelErrorMessage>
            {errors?.password?.message || ""}
          </LabelErrorMessage>
        </div>
        <LabelTitleField marginTop="24px">{app.strings.fields.confirmNewPassword}</LabelTitleField>
        <TextField
          placeholder={app.strings.fields.typeNewPassword}
          type="password"
          onChangeText={(confirmPassword) => {
            setValue("confirmPassword", confirmPassword, { shouldValidate: true });
            setErrorMessage("");
          }}
          value={confirmPassword}
        />
        <PasswordStrengthMeter password={confirmPassword} />
        <div>
          <LabelErrorMessage>
            {errorMessage || errors?.confirmPassword?.message || ""}
          </LabelErrorMessage>
        </div>
        <ButtonColor
          title={app.strings.buttons.resetPassword}
          marginTop="20px"
          marginBottom="56px"
        />
      </form>
    </DivContainer>
  );
};