import React, { useState, useEffect, useMemo } from 'react';
import { observer } from "mobx-react";
import { yupResolver } from '@hookform/resolvers';
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
// import FormAuth from "@src/components/FormAuth";
// import TextField from "@src/components/TextField";
// import ButtonColor from "@src/components/ButtonColor";
// import { signup } from "@src/core/server/auth";
// import PasswordStrengthMeter from "@src/components/PasswordStrengthMeter";
import FormAuth from "../../components/FormAuth";
import TextField from "../../components/TextField";
import ButtonColor from "../../components/ButtonColor";
import { signup } from "../../core/server/auth";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter";
import { getParamsHttpLine } from '../../core/service';
import { useStores } from '../../core/hooks/useStores';
import { WIDTH_MAIN_FORM } from '../../constants/System';

const DivSignUp = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const LabelTitleTextField = styled.label`
  display: flex;
  color: ${props => props.theme.formAuthTextLabel};
  height: 14px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
`
const LabelErrorMessage = styled.label`
  font-family: Rubik;
  color: red;
  font-size: 12px;
  height: 8px;
`
const DivSignIn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  height: 20px;
`
const LinkSignIn = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  color: #f0830e;
  font-family: Rubik;
`
const LabelSignIn = styled.label`
  font-size: 12px;
  font-family: Rubik;
  color: ${props => props.theme.formAuthTextSubtitle};
`

export const SignUp = observer((props) => {
  const { app } = useStores()

  const SignUpForm = (restProps) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [redirect, setRedirect] = useState()

    const schema = yup.object().shape({
      email: yup
        .string()
        .email(restProps.states.strings.fields.emailInvalid)
        .required(restProps.states.strings.fields.emailRequired),
      password: yup
        .string()
        .min(3, restProps.states.strings.fields.minCharacters)
        .required(restProps.states.strings.fields.passwordRequired),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], restProps.states.strings.fields.passwordsMatch)
        .required(restProps.states.strings.fields.passwordRequired),
    });

    const { register, setValue, watch, handleSubmit, errors, trigger } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema)
    });
    useEffect(() => {
      if (errors.email) trigger('email') 
      if (errors.password) trigger('password')
      if (errors.confirmPassword) trigger('confirmPassword')
    }, [errors, trigger, restProps.states.lang])
    useEffect(() => {
      setErrorMessage('')
    }, [restProps.states.lang])

    useEffect(() => {
      register('email')
      register('password')
      register('confirmPassword')
    }, [register])

    useEffect(() => {
      let response = getParamsHttpLine()
      if (response && response.redirect) setRedirect(response.redirect)
    }, [])

    useEffect(() => {
      restProps.states.setPlaceSignIn(false)
    }, [restProps.states])

    const { email, password, confirmPassword } = watch()

    const handleOnSubmit = async formData => {
      formData['redirect'] = redirect
      const data = await signup({
        email: formData.email,
        password: formData.password,
        lang: restProps.states.lang,
      });
      if (data.isError)
			  return setErrorMessage(data.errors[0].message)
		  else {
        restProps.history.push({        
          pathname: '/check-inbox',
          state: { email: formData.email }
        })
        // if (data.response.redirect) {
        //   window.location.assign(data.response.redirect)
        // } else alert("Account has been created. We haven't url for redirect.")
      }
    }

    return (
      <>
        <DivSignUp style={restProps.style}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <LabelTitleTextField>{restProps.states.strings.fields.email}</LabelTitleTextField>
            <TextField
              placeholder={restProps.states.strings.fields.typeEmail}
              type='text'
              onChangeText={(email) => {
                setValue('email', email, { shouldValidate: true })
                setErrorMessage('')
              }}
              value={email}
            />
            <LabelErrorMessage>
              {errors?.email?.message || ''}
            </LabelErrorMessage>
            <LabelTitleTextField>{restProps.states.strings.fields.password}</LabelTitleTextField>
            <TextField
              placeholder={restProps.states.strings.fields.typePassword}
              type='password'
              onChangeText={(password) => {
                setValue('password', password, { shouldValidate: true })
                setErrorMessage('')
                if (confirmPassword) trigger('confirmPassword')
              }}
              value={password}
            />
            <PasswordStrengthMeter password={password} />
            <LabelErrorMessage>
              {errors?.password?.message || ''}
            </LabelErrorMessage>
            <LabelTitleTextField>{restProps.states.strings.fields.confirmPassword}</LabelTitleTextField>
            <TextField
              placeholder={restProps.states.strings.fields.typePassword}
              type='password'
              onChangeText={(confirmPassword) => {
                setValue('confirmPassword', confirmPassword, { shouldValidate: true })
                setErrorMessage('')
              }}
              value={confirmPassword}
            />
            <div>
              <LabelErrorMessage>
                { errorMessage || errors?.confirmPassword?.message || ''}
              </LabelErrorMessage>
            </div>
            <ButtonColor title={restProps.states.strings.buttons.create} />
          </form>
        </DivSignUp>
        <DivSignIn>
          <LinkSignIn to={'/login' + window.location.search}>
            {restProps.states.strings.texts.signIn}.
          </LinkSignIn>
          <LabelSignIn>&nbsp;{restProps.states.strings.fields.haveAccount}</LabelSignIn>
        </DivSignIn>
      </>
    )
  }
  const memoizedValue = useMemo(() => SignUpForm, [])

  return (
      <FormAuth 
        width={WIDTH_MAIN_FORM}
        title={app.strings.texts.signUp}
        history={props.history}
        subtitle={app.strings.formatString(app.strings.texts.enterEmail, <br />)}
        states={app}
        FormMain={memoizedValue} />
  );
});