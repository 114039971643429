import React, { useMemo, useEffect, useState } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
import FormAuth from "../../components/FormAuth";
import { useStores } from "../../core/hooks";
import { getParamsHttpLine } from "../../core/service";
import { WIDTH_MAIN_FORM } from "../../constants/System";

const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 48px;
  width: 100%;
`
const FormActiveButton = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const DivActiveButton = styled.div`
  display: flex;
  width: 100%;
  justify-content:space-between;
`
const InputButton = styled.input`
  width: 152px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.buttonLogoutBorder};
  background-color: rgba(255,255,255,0.0);
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0.86px;
  text-align: center;
  color: ${props => props.theme.mainBottomNavigationTextLanguageNotActive};

  &:focus {
    outline:0;
  }
  &:hover {
    box-shadow: 0px 9px 21px ${props => props.theme.textfieldBoxShadowHover}
  }
`

export const ApproveService = observer(() => {
  const { app } = useStores();
  const [clientOrigin, setClientOrigin] = useState("");
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    let response = getParamsHttpLine();
    if (response && response.clientOrigin) setClientOrigin(response.clientOrigin)
    if (response && response.transactionId) setTransactionId(response.transactionId)
  }, []);

  const ApproveServiceForm = (restProps) => {

    return (
      <DivContainer style={restProps.style}>
        <FormActiveButton action="/api/auth/decision" method="post">
          <input name="transaction_id" type="hidden" value={restProps.states.transactionId} />
          <DivActiveButton>
            <InputButton
              type="submit"
              value={restProps.states.app.strings.buttons.deny}
              name="cancel"
              id="deny" />
            <InputButton
              type="submit"
              value={restProps.states.app.strings.buttons.approve}
              id="allow" />
          </DivActiveButton>
        </FormActiveButton>
      </DivContainer>
    );
  };

  const memoizedValue = useMemo(() => ApproveServiceForm, []);

  return (
    <FormAuth
      width={WIDTH_MAIN_FORM}
      marginTopSubtitle="48px"
      subtitle={app.strings.formatString(
        app.strings.texts.requesting,
        clientOrigin,
        <br />
      )}
      states={{app, transactionId}}
      FormMain={memoizedValue}
    />
  );
});