import React, { useEffect, useState, useMemo } from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { WIDTH_DEVICE } from "@src/constants/constants";
// import { useStores } from '@src/core/hooks';
import { useStores } from '../core/hooks';

const DivAuth = styled.div`
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.formAuthBackground};
  justify-content: flex-start;
  border-radius: 8px;
  padding-top: 48px;
  ${'' /* align-items: center; */}
  box-shadow: ${props => props.formAuthBoxShadow};
`
const LabelTitle = styled.label`
  display: flex;
  color: ${props => props.theme.formAuthTextTitle};
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0px;
`
const LabelSubtitle = styled.label`
  ${'' /* display: flex; */}
  color: ${props => props.theme.formAuthTextSubtitle};
  font-family: Rubik;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1.38;
  font-size: 16px;
  margin-top: ${props => props.marginTop || "24px"};
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  ${'' /* flex-direction: column; */}
`
const DivLogo = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
  margin-bottom: 30px;
`

export const FormAuth = ({redirectLogin, ...props}) => {
  const { app } = useStores()
  const [email, setEmail] = useState(app.getEmail())

  useEffect(() => {
    if (typeof email === "undefined" && redirectLogin) {
      window.location.assign('/login')
    }
  }, [email, redirectLogin])

  useEffect(() => {
    setEmail(app.getEmail())
  }, [app, app.email])


  const FormAuthInner = ({
    FormMain,
    width,
    title,
    subtitle,
    marginTopSubtitle,
    history,
    states,
    logo,
  }) => {
  
    const styleMainForm = {
      paddingLeft: 14,
      paddingRight: 14,
      maxWidth: 420, 
      // position: absolute,
    }
    return (
      <DivAuth>
        {logo && <DivLogo><img width="100%" src={logo} alt=""/></DivLogo>}
        {title && <LabelTitle>{title}</LabelTitle>}
        {subtitle && (
          <LabelSubtitle marginTop={marginTopSubtitle}>
            {subtitle}
          </LabelSubtitle>
        )}
        <FormMain style={styleMainForm} history={history} states={states} />
      </DivAuth>
    );
  };

  const memoizedValue = useMemo(() => FormAuthInner(props), [props])

  return (
    <>{redirectLogin ? email ? memoizedValue : <></> : memoizedValue}</>
  );
}

FormAuth.propTypes = {
  FormMain: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  title: PropTypes.string,
  marginTopSubtitle: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  history: PropTypes.object,
  states: PropTypes.object
};

FormAuth.defaultTypes = {
  marginTopSubtitle: '0px',
  subtitle: '',
  title: undefined,
  history: {},
  states: {}
};

export default FormAuth;
