import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { useStores } from '@src/core/hooks';
import { useStores } from '../core/hooks';

const ButtonStyled = styled.button`
  background-color: ${props => props.colors.bg};
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: ${props => props.padding.top};
  padding-bottom: ${props => props.padding.bottom};
  margin-top: ${props => props.margin.top};
  margin-bottom: ${props => props.margin.bottom};

  border: 1px solid ${props => props.colors.border};
  box-shadow: 0 9px 21px 0 ${props => props.colors.boxSh};
  border-radius: 4px;
  font-family: Rubik;
  font-weight: normal;
  text-align: center;

  font-size: ${props => props.size.fontSize};
  line-height: ${props => props.size.lineHeight};
  
  color: ${props => props.colors.clr};
  
  &:focus {
    outline:0;
  }

  @media (min-width: 450px) {
    &:hover {
      background-color: ${props => props.colors.bgHover};
      box-shadow: 0px 4px 20px 0px ${props => props.colors.boxShHover};
    }
  }
  @media (max-width: 450px) {
    &:active {
      background-color: ${props => props.colors.bgHover};
      box-shadow: 0px 4px 20px 0px ${props => props.colors.boxShHover};
    }
  }
`

export const ButtonColor = ({ title, colorType, size, paddingTop, paddingBottom, onClick, marginTop, marginBottom, textStyle, icon }) => {
  const { app } = useStores()
  const { colors } = app.getTheme(app.mode)

  const colorMain = {
    bg: colors.buttonMainBg,
    border: colors.buttonMainBorder,
    boxSh: colors.buttonMainBoxSh,
    bgHover: colors.buttonMainBgHover,
    boxShHover: colors.buttonMainBoxShHover,
    clr: colors.buttonMainClr,
  }
  const colorNone = {
    bg: colors.buttonLogoutBg,
    border: colors.buttonLogoutBorder,
    boxSh: colors.buttonLogoutBoxSh,
    bgHover: colors.buttonLogoutBgHover,
    boxShHover: colors.buttonLogoutBoxShHover,
    clr: colors.buttonLogoutClr,
  }
  const sizeNormal = {
    fontSize: '12px',
    lineHeight: '14px',
  }
  const sizeBig = {
    fontSize: '16px',
    lineHeight: '19px',
  }

  return (
    <ButtonStyled 
      type="submit" 
      colors={ colorType === "none" ? colorNone : colorMain } 
      size={ size === "big" ? sizeBig : sizeNormal } 
      padding={{ top: paddingTop, bottom: paddingBottom }}
      margin={{ top: marginTop, bottom: marginBottom }}
      style={textStyle}
      onClick={onClick}>
      {icon && <img src={icon} width='20px' height='100%' style={{ margin: '-5px 5px -5px 0' }} alt=""/>}
      {title}
    </ButtonStyled>
  );
};

ButtonColor.propTypes ={
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  colorType: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  size: PropTypes.string,
  textStyle: PropTypes.object
}

ButtonColor.defaultProps = {
  colorType: "",
  size: "normal",
  onClick: () => {},
  paddingTop: '13px', 
  paddingBottom: '13px',
  marginTop: '20px',
  marginBottom: '20px',
  textStyle: {}
}

export default ButtonColor;