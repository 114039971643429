import React, { useMemo } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import FormAuth from "@src/components/FormAuth";
import FormAuth from "../../components/FormAuth";
import { useStores } from "../../core/hooks";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const LabelDescription = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  font-size: 16px;
  font-family: Rubik;
  font-weight: normal;
  text-align: center;
  margin-bottom: 30px;
`
const LabelEmail = styled.label`
  color: ${props => props.theme.textOrange};
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  text-align: center;
  margin-bottom: 48px;
`

export const CheckInbox = observer((props) => {
  const { app } = useStores()
  
  const CheckInboxForm = (restProps) => {
    console.log(restProps)
    return (
      <DivContainer style={restProps.style}>
        <LabelDescription> 
          {restProps.states.app.strings.texts.sentLink}
        </LabelDescription>
        {!!restProps.states.state && !!restProps.states.state.email && <LabelEmail>
          {restProps.states.state.email}
        </LabelEmail>}

      </DivContainer>
    )
  }

  const memoizedValue = useMemo(() => CheckInboxForm, [])

  return (
      <FormAuth 
        width="432px" 
        title={app.strings.texts.checkInbox}
        states={{ state: props.location.state, app }}
        FormMain={memoizedValue} />
  );
});