import { observable, action, decorate, computed } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';
import { get } from 'lodash';
// import baseTheme from '@src/core/themes/theme';
// import { WIDTH_DEVICE } from '@src/constants/constants';
// import { getTheme, setTheme, getEmailStore, removeEmailStore, setEmailStore } from '@src/core/storage';
import baseTheme from '../../core/themes/theme';
import { WIDTH_DEVICE } from '../../constants/System';
import { en } from '../../locales/en.json';
import { ja } from '../../locales/ja.json';

import {
	getTheme,
	setTheme,
	getEmailStore,
	removeEmailStore,
	setEmailStore,
	setToken as setTokenStore,
	removeToken as removeTokenStore,
	setPlaceSignIn as setPlaceSignInStore,
	getPlaceSignIn as getPlaceSignInStore,
	getLanguage,
	setLanguage,
	getTwoFactorStore
} from '../../core/storage';
import LocalizedStrings from 'react-localization';
const DEFAULT_LANGUAGE = "en";

export class AppStore {
	constructor() {
		const data = getLanguage()
		this.strings = new LocalizedStrings({en, ja});
		let langInterface = navigator.language

		var language = "en"
		if ((langInterface.toLowerCase().indexOf("ja") + 1)
			|| (langInterface.toLowerCase().indexOf("jv") + 1))
			language = "ja";
		else
			language = "en";

		if (!(data && this.setLanguage({ name: data }))) {
			console.log("set 1 no")
			if (!this.setLanguage({ name: language })) {
				console.log("set 2 no")
				this.setLanguage({ name: DEFAULT_LANGUAGE })
			}
		}
	}
	mode = getTheme()
	modes = ['light', 'dark']
	email = getEmailStore()
	google2Fa = getTwoFactorStore()
	token = undefined
	isSignIn = true
	lang = "en"

	languages = [
    { name: 'en', label: 'English', selected: false },
    { name: 'ja', label: 'Japan', selected: false }
	]

	get language() {
    return this.languages.find((language) => language.selected)
  }

	updateLanguage() {
		let langInterface = navigator.language
		var language = "en"
		if ((langInterface.toLowerCase().indexOf("ja") + 1)
			|| (langInterface.toLowerCase().indexOf("jv") + 1)) {
			language = "ja"
		} else {
			language = "en"
		}
		this.setLanguage({ name: language }, true);
	}

  setLanguage({ name }, saveStorage = false) {
    if (!this.languages.find((language) => language.name === name))
      return false

    if (saveStorage)
			setLanguage(name)
		this.strings.getLanguage()

    // i18n.locale = name
    // // Set for update components are used the translate method.
    // set(this, 'translate', (...args) => i18n.t(...args))
    // this.languages = this.languages.map((language) => {
    //   if (language.name === name)
    //     return { ...language, selected: true }
    //   else
    //     return { ...language, selected: false }
		// })
		if (name === 'en') {
			this.strings.setLanguage('en')
			this.lang="en"
		} else {
			this.strings.setLanguage('ja')
			this.lang="ja"
		}
		// this.strings.setLanguage(name)
		// this.strings.setLanguage('en')
		// this.strings.setLanguage('en')
		this.languages = this.languages.map((language) => {
      if (language.name === name)
        return { ...language, selected: true }
      else
        return { ...language, selected: false }
    })

    return true
  }

	setMode(mode) {
		this.mode = mode
		setTheme(mode)
	}

	getLang() {
    return { langs: this.strings }
	}

	getTheme(mode) {
		return { colors: get(baseTheme.colors.modes, mode, baseTheme.colors.modes.light) }
	}

	getEmail() {
		this.email = getEmailStore()
		return this.email
	}

	removeEmail() {
		removeEmailStore()
		this.email = undefined
	}

	setEmail(email) {
		this.email = email
		setEmailStore(email)
	}

	removeToken() {
		removeTokenStore()
		this.token = undefined
	}

	setToken(token) {
		this.token = token
		setTokenStore(token)
	}

	getPlaceSignIn() {
		this.isSignIn = getPlaceSignInStore()
		return this.isSignIn
	}

	setPlaceSignIn(isSignIn) {
		this.isSignIn = isSignIn
		setPlaceSignInStore(isSignIn)
	}

	getLogo() {
		if (window.screen.width > WIDTH_DEVICE)
      switch (this.mode) {
        case 'light':
          return require(`../../assets/images/logo-light.svg`)
        case 'dark':
          return require(`../../assets/images/logo-dark.svg`)
        default:
          return require(`../../assets/images/logo-light.svg`)
      }
    else
    	return require(`../../assets/images/logo-mobile.svg`)
	}
}

decorate(AppStore, {
	strings: observable,
	lang: observable,
	mode: observable,
	email: observable,
	token: observable,
	isSignIn: observable,
	modes: observable,
	languages: observable,
	setMode: action,
	getTheme: action,
	updateLanguage: action,
	getLang: action,
	setEmail: action,
	removeEmail: action,
	language: computed,
	getEmail: action,
	setToken: action,
	removeToken: action,
	getPlaceSignIn: action,
	setPlaceSignIn: action
})
