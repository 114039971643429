import React, { useMemo, useEffect, useState, useCallback } from "react";
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import FormAuth from "@src/components/FormAuth";
import FormAuth from "../../components/FormAuth";
import ButtonColor from '../../components/ButtonColor';
import { useStores } from "../../core/hooks";
import { checkCode, approve } from "../../core/server/auth";
import { getParamsHttpLine } from "../../core/service";
import { ResetPasswordSend } from "../ForgotPassword/components/ResetPasswordSend";
import { WIDTH_MAIN_FORM } from "../../constants/System";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`
const LabelDescription = styled.label`
  color: ${props => props.theme.formAuthTextLabel};
  font-size: 16px;
  font-family: Rubik;
  font-weight: normal;
  text-align: center;
  margin-bottom: 25px;
`
const ImageContainer = styled.div`
  color: ${props => props.theme.textOrange};
  display: flex;
  align-self: center;
  height: 80px;
  width: 80px;

  img {
    height: 100%;
  }
`

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const RESET_PASSWORD = 'RESET_PASSWORD';

export const ApproveCode = observer((props) => {
  const { app } = useStores()

  const [isApproved, setIsApproved] = useState(false)
  const [id, setId] = useState()
  const [code, setCode] = useState()
  const [type, setType] = useState()
  const [error, setError] = useState('')
  const isIOS = navigator.userAgent.match(/iPhone/i)
  const [start, setStart] = useState(true)
  const [back, setBack] = useState(false)

  const detectMob = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i];
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  const checkApprove = async (lang) => {
    let response = getParamsHttpLine()
    setId(response._id)
    setCode(response.code)

    const data = await checkCode({ _id: response._id, code: response.code, lang: lang })
    if (data.isError) return setError(data.errors[0].message)
    else {
      const dataApprove = await approve({ _id: response._id, code: response.code, lang: lang })
      if (dataApprove.isError)
        setError(dataApprove.errors[0].message);
      else
        setIsApproved(true);
      setType(data.response.approveAction.actionName);
    }
  }

  const checkApproveMobile = async () => {
    let response = getParamsHttpLine()
    setId(response._id)
    setCode(response.code)

    document.location = `platinum.mobile.stage:///approve-code?_id=${response._id}&code=${response.code}`;
  }

  const callbackApprove = useCallback(() => {

    const mobileApprove = async () => {
      let response = getParamsHttpLine()
      setId(response._id)
      setCode(response.code)
      const data = await checkCode({ _id: response._id, code: response.code, lang: app.lang })
      if (data.isError) return setError(data.errors[0].message)
      else {
        const dataApprove = await approve({ _id: response._id, code: response.code, lang: app.lang })
        if (dataApprove.isError)
          setError(dataApprove.errors[0].message);
        else
          setIsApproved(true);
        setType(data.response.approveAction.actionName);
      }
    }

    mobileApprove()
  }, [app.lang])

  useEffect(() => {
    const isMobile = detectMob()

    if (isMobile) {
      if (!isIOS) { // Android mobile
        checkApproveMobile()
        setTimeout(()=>{ 
          callbackApprove()
        }, 1500)
      } else { // iOS mobile
        
      }
    }
  },[callbackApprove, isIOS])
  
  useEffect(() => {
    const isMobile = detectMob()
    if (!isMobile) checkApprove(app.lang)
  },[app.lang])

  const handleContinueButtonWeb = async (isWeb) => {
    setStart(false)
    if (isWeb) {
      checkApprove(app.lang)
    } else {
      checkApproveMobile()
      setTimeout(()=>{ 
        setBack(true)
      }, 1500)
    }
  }
  const handleBackButtonOptions = async () => {
    setStart(true)
    setBack(false)
  }
// Form main screen with logic
  const ApproveCodeForm = (restProps) => {
    var buttonTitle = restProps.states.isApproved
      ? restProps.states.app.strings.buttons.continue
      : restProps.states.app.strings.buttons.tryAgain;

    const onPressButton = () => {
      if ( restProps.states.type === EMAIL_VERIFICATION ) {
        if ( restProps.states.isApproved ) {
          window.location.assign('/login')
        } else {
          restProps.states.checkApprove(restProps.states.app.lang)
        }
      } else if ( restProps.states.type === RESET_PASSWORD ) {
        if ( restProps.states.isApproved ) {
          window.location.assign('/login')
        } else {
          restProps.states.checkApprove(restProps.states.app.lang)
        }
      }
    }

    return (
      <DivContainer style={restProps.style}>
      <LabelDescription>
        {restProps.states.description}
      </LabelDescription>
        <ImageContainer>
          <img src={restProps.states.isApproved ? require('../../assets/images/smile_grac.png') : require('../../assets/images/smile_fail.png')} alt=""/>
        </ImageContainer>
        <ButtonColor title={buttonTitle} marginTop={'43px'} marginBottom={'56px'} onClick={onPressButton}/>
      </DivContainer>
    )
  }
// Form for iOS started
  const ApproveCodeFormIOS = (restProps) => {
    return (
      <DivContainer style={restProps.style}>
        <ButtonColor
          title={"Continue with mobile app"}
          marginTop={"28px"}
          marginBottom={"0px"}
          onClick={() => restProps.states.handleContinueButtonWeb(false)}
        />
        <ButtonColor
          title={"Continue with web version"}
          marginTop={"20px"}
          marginBottom={"48px"}
          onClick={() => restProps.states.handleContinueButtonWeb(true)}
        />
      </DivContainer>
    );
  }
  const ApproveCodeFormIOSRedirectStart = (restProps) => {
    return (
      <DivContainer style={restProps.style}>
        <ButtonColor
          title={"Back to choosing options"}
          marginTop={"20px"}
          marginBottom={"48px"}
          onClick={() => restProps.states.handleBackButtonOptions()}
        />
      </DivContainer>
    );
  }

  const memoizedValue = useMemo(() => ApproveCodeForm, []);
  const memoizedValueIOS = useMemo(() => ApproveCodeFormIOS, []);
  const memoizedValueIOSRedirectStart = useMemo(() => ApproveCodeFormIOSRedirectStart, []);

  var description =
    type === EMAIL_VERIFICATION 
      ? isApproved 
        ? <>{app.strings.formatString(app.strings.texts.accountRegistered, <br />)}</>
        : <>{app.strings.formatString(app.strings.texts.accountNotConfirmed, <br />)}</>
    : type === RESET_PASSWORD 
      ? isApproved 
        ? <>{app.strings.texts.continueWork}</> 
        : <>{app.strings.texts.somethingWrong}</>
      : <label style={{color:'red'}}>{error}</label>

  return (
    <>
    { isIOS && start 
      ? <FormAuth
          width={WIDTH_MAIN_FORM}
          states={{ ...props.location.state, 
                    app: app,
                    handleContinueButtonWeb: handleContinueButtonWeb }}
          FormMain={memoizedValueIOS} />
    : isIOS && back 
      ? <FormAuth
          width={WIDTH_MAIN_FORM}
          states={{ ...props.location.state, 
                    app: app,
                    handleBackButtonOptions: handleBackButtonOptions }}
          FormMain={memoizedValueIOSRedirectStart} />
    :
    type === EMAIL_VERIFICATION 
      ? <FormAuth
          width={WIDTH_MAIN_FORM}
          title={isApproved ? app.strings.texts.congratulations : app.strings.texts.error}
          states={{ ...props.location.state, 
                    type: type, 
                    isApproved: isApproved, 
                    description: description, 
                    redirect: '',
                    app: app,
                    checkApprove: checkApprove }}
          FormMain={memoizedValue} />
      : type === RESET_PASSWORD 
        ? isApproved
          ? <FormAuth
              width={WIDTH_MAIN_FORM}
              title={app.strings.texts.congratulations}
              states={{ ...props.location.state, 
                    type: type, 
                    isApproved: isApproved, 
                    description: description, 
                    redirect: '',
                    app: app,
                    checkApprove: checkApprove }}
              FormMain={memoizedValue} />
          : <FormAuth
              width={WIDTH_MAIN_FORM}
              title={app.strings.texts.resetPassword}
              states={{ ...props.location.state, id: id, code: code, app: app }}
              FormMain={ResetPasswordSend} />
      : !!error 
          ? <FormAuth
              width={WIDTH_MAIN_FORM}
              title={app.strings.texts.error}
              states={{ ...props.location.state, 
                id: id, 
                code: code,
                app: app,
                description: description}}
              FormMain={memoizedValue} />
          : <></>}
    </>
  );
});