
export const getParamsHttpLine = () => {
  let params = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      var a = e.split('=')
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1])
      return p
    }, {})
  var response = {}
  if (params && params['redirect']) response['redirect'] = params['redirect']
  if (params && params['_id']) response['_id'] = params['_id']
  if (params && params['code']) response['code'] = params['code']
  if (params && params['clientOrigin']) response['clientOrigin'] = params['clientOrigin']
  if (params && params['transactionId']) response['transactionId'] = params['transactionId']

  return response
}