import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import FormAuth from "../../components/FormAuth";
import { useStores } from '../../core/hooks';
import {
  disableGoogle2FA,
  enableGoogle2FA,
  getGoogle2FA,
} from "../../core/server/auth";
import * as QRCode from 'qrcode.react';
import TextField from "../../components/TextField";
import ButtonColor from '../../components/ButtonColor';


const DivForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
`
const BoxQr = styled.div`
  display: box;
  text-align: center;  
`
const DivSpace = styled.div`
  flex-direction: column;
  height: 24px;
`
const LabelErrorMessage = styled.label`
  font-family: Rubik;
  color: red;
  font-size: 12px;
  height: 8px;
`
const DivButtonAction = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const Google2FA = () => {
  const { app } = useStores()
  const TwoFactorForm = (restProps) => {
    const [isEnabled, setEnabled] = useState(false)
    const [url, setUrl] = useState('');
    // const [totp,setTotp] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
      const twoFactorData = async () => {
        const data = await getGoogle2FA()
        if (!data.isError) {
          if(data.response.isEnabled) {
            setEnabled(true)
          } else {
            setEnabled(false)
            setUrl(data.response.url);
            // setTotp(data.response.code);
          }
        }
      }

      twoFactorData().then()
    }, [restProps.states.app])
    const handleEnable = async () => {
      setError('');
      const data = await enableGoogle2FA({
        token: code
      });
      if (data.isError) {
        setError(data.response.error);
      } else {
        window.location.assign('profile/');
      }
    }
    const handleDisable = async () => {
      setError('');
      const data = await disableGoogle2FA();
      if (data.isError) {
        setError(data.response.error);
      } else {
        window.location.assign('profile/');
      }
    }
    if (isEnabled){
      return (
        <DivForm style={restProps.style} >
          <LabelErrorMessage>
            {error}
          </LabelErrorMessage>
          <DivButtonAction>
            <ButtonColor title={restProps.states.app.strings.buttons.disable} onClick={handleDisable}/>
          </DivButtonAction>
        </DivForm>
      )
    } else {
      return (
        <DivForm style={restProps.style} >
          <DivSpace/>
          <BoxQr>
            <QRCode value={url} size={256}/>
          </BoxQr>
          <DivSpace/>
          <TextField
            placeholder={restProps.states.app.strings.fields.enterTheCode}
            type="text"
            onChangeText={setCode}
            value={code}
          />
          <LabelErrorMessage>
            {error}
          </LabelErrorMessage>
          <DivButtonAction>
            <ButtonColor title={restProps.states.app.strings.buttons.enable} onClick={handleEnable}/>
          </DivButtonAction>
        </DivForm>
      )
    }
  }

  return (
    <FormAuth
      width="430px"
      title={app.strings.texts.google2fa}
      redirectLogin={true}
      states={{app}}
      FormMain={TwoFactorForm}/>
  );
};
